import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const  Testimonials=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, 
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1, 
                slidesToScroll: 1,
              },
            },
          ],
       
      };


      const testimonialSlides = [
        {
          imageSrc: "/boy.png",
          alt: "Customer Testimonials",
          content: "“This team of experts and strategic minds helped us to increase sales for my Ecommerce business. They work on each and every need for our business growth as they are known to keep their promises.”",
          author: "- Raghuveer Modili"
        },
        {
          imageSrc: "/girl.png",
          alt: "Customer Testimonials",
          content: "“It's the most pleasant experience to work with the team of creative brains. We Think helped me with our website redesign which now looks so appealing and amazing. I am so impressed with their dedication and teamwork.”",
          author: "- Vedasree"
        }
      ];

    return(
        <> 

          <section className="section">
          <Container maxWidth='lg' >

          <Box>
          <div>

            <div style={{padding:isSmscreen?'20px 0px 36px':'20px 0px 56px',textAlign:'center',}}>
          <h3 className ="heading_title" style={{margin:'0px',paddingBottom: isSmscreen?'0px':'10px'}}>Customer Testimonials</h3>
          <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="Benefits of Choosing Us"
                    loading="lazy"
                   
                />

         </div>

          <LazyLoadImage
                style={{maxWidth:'100%', height:isSmscreen?'60px':'100%'}}
                src="/testimonilas.svg"
                alt="Customer Testimonials"
                loading="lazy"
             

            /> 

        </div>
             
        <Slider {...settings}>
      {testimonialSlides.map((slide, index) => (
        <div key={index} className={`testimonial-slide ${index === testimonialSlides.length - 1 ? 'last-slide' : ''}`}>
         <div className="testimonial_image">
          <LazyLoadImage
            
            src={slide.imageSrc}
            alt={slide.alt}
            loading="lazy"
          
          />
          </div>
          <div>
            <div className="title"  style={{lineHeight:'24px'}}>{slide.content}</div>
            <div className="footer_primary_title testimonial_autor">{slide.author}</div>
          </div>

        </div>
      ))}
    </Slider>
         
          </Box>

           </Container>
           </section>
        </>
    )
}

export default Testimonials;