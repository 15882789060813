import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const GlimpseUI=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const responsive = {
        
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const slides = [
        {
          link: "https://www.behance.net/gallery/76491863/Orchid-Mobile-app",
          imageSrc: "/glimpses2.png",
          alt: "Orchid"
        },
        {
          link: "https://www.instagram.com/adidhi.app/",
          imageSrc: "/glimpses3.png",
          alt: "Adidhi"
        },
        {
          link: "https://www.behance.net/gallery/61573557/Discover-Service-Apartment-Web-Design-UIUX",
          imageSrc: "/glimpses1.png",
          alt: "On Road App"
        }
      ];
     

    return(
        <>

          <section className="section">
          <Container maxWidth='lg'>
          <Box sx={{width:'100%'}}>
            <h3 className="page_title" style={{textAlign:'center',margin:'0px 0px 12px'}}>Glimpses Of Our Past Works!</h3>

            <p className='title' style={{fontWeight:500, margin:'0px' ,lineHeight:'24px', color:'var(--secondary)',textAlign:'center'}}><b>Three years</b> of crafting sweet experiences with Research, Design & Development.</p>

            <div style={{display:"flex",justifyContent:'center', padding:isSmscreen?'16px 0px 32px':'20px 0px 42px'}}>
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="UX-Design"
                    loading="lazy"
                 
                />
                   </div>

                   <Carousel
                        responsive={responsive}
                        className="glimpsesUI"
                        >
                        {slides.map((slide, index) => (
                            <div key={index}>
                            <NavLink to={slide.link} className="home" target="_blank">
                                <LazyLoadImage
                                className="glimpseimage"
                                src={slide.imageSrc}
                                alt={slide.alt}
                                loading="lazy"
                                />
                            </NavLink>
                            </div>
                        ))}
                        </Carousel>

      
            </Box>

           </Container>
           </section>
        </>
    )
}

export default GlimpseUI;

/* 
     
      
         
 
 */