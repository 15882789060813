import React from 'react';
import { Box, Container, Button, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { createTheme, useMediaQuery } from "@mui/material";

const  Contact=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
    
    
     const customButton=
     {
    
        
        color:'#000000',
        fontSize:'18px',
        padding:'12px 24px',
        border:'1px solid #000000',
        width:'fit-content',
        fontWeight: 600,
        textTransform:'none',
        margin:isSmscreen?'10px 0px 20px':'20px 0px 30px',
        letterSpacing: '0.8px',  
        '&:hover': {
        backgroundColor:'none',
     },
     }


 const navigate=useNavigate()

const Contactus=()=>
{
   window.scrollTo({ top: 0, behavior:'instant' });
   navigate("/contact-us")
}

    return (
        <>
        
        <section className="section" style={{backgroundColor:'#FFFCEC'}}>
           <Container maxWidth="lg">
             
               
            <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={{xs:2,sm:4,md:6}} columnSpacing={{ sm:4,md:6}}  display="flex" justifyContent="center" >
          
              <Grid item xs={12} sm={10} md={10}>

              <div className="project_form" style={{marginBlock:'0px'}}>
               <h3 className="page_title" style={{marginBlock:'0px'}}>
             Getting On-Board To Unleash Business Game-Plan We make the impossible happen</h3>
              
              <div style={{display:'flex',justifyContent:'center'}}>
               <Button style={customButton} onClick={Contactus}>Contact us</Button>
               </div>
                </div>

                </Grid>

                </Grid>
                
                </Box>
                </Container>
                </section>
        </>
    )
}

export default Contact;