import React from "react";
import Join from "./Join";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Help from "../Home/Help";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';

const Contact=()=>
{

    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>
          
          <Helmet>

          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
         
            <div className="content">
            <Header/>
               <Join/>
               <Help/>
             </div>
             <Footer/>
       
        </>
    )
}
export default Contact;