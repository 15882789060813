import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LazyLoadImage } from "react-lazy-load-image-component";


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: '16px 18px',
    marginTop: '20px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out', 
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }));

const Superheroes=()=>
{
 
     

    return (
        <>
          
           
           
           
          <section className="section">
            <Container maxWidth='lg'>
           

          <Box className='superheroes'>
            
          <h1 className="page_title text-center" style={{margin:'0px'}}>  Our Superheros! </h1>
          <p className="section_title text-center semibold" style={{margin:'12px 0px 20px'}}>If you are good, good on the team!</p>

         <div style={{display:'flex',justifyItems:'center'}}>
          <LazyLoadImage
               
                    src="/Rectangle.svg"
                    alt="Our Superheros"
                    loading="lazy"
                  
                />
               </div>
          </Box>

          <Box>
            <Grid container display="flex" justifyContent="start" rowSpacing={2} columnSpacing={4} style={{padding:'20px 0px'}}>
                <Grid item xs={6} sm={3} md={3} >
                  <Item>
                    <Box>
                        <img style={{maxWidth:'100%'}} src='/team1.jpeg' alt='team-1'/>
                    </Box>
                    <Box style={{paddingTop:'8px'}}>
                        <div style={{fontFamily:'Plus Jakarta Sans, sans-serif'}}>FOUNDER & CEO</div>
                        <div style={{fontSize:'16px', fontWeight:'550', fontFamily:'Plus Jakarta Sans, sans-serif'}}>Robin Padhi</div>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={6} sm={3} md={3} >
                  <Item>
                    <Box>
                    <img style={{maxWidth:'100%'}} src='/team-2.png' alt='team-2'/>
                    </Box>
                    <Box style={{paddingTop:'8px'}}>
                        <div style={{fontFamily:'Plus Jakarta Sans, sans-serif'}}>CO-FOUNDER</div>
                        <div style={{fontSize:'16px', fontWeight:'550',fontFamily:'Plus Jakarta Sans, sans-serif'}}>Pavana B</div>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={6} sm={3} md={3} >
                  <Item>
                    <Box>
                    <img style={{maxWidth:'100%'}} src='/team-3.png' alt='team-3'/>
                    </Box>
                    <Box style={{paddingTop:'8px'}}>
                        <div style={{fontFamily:'Plus Jakarta Sans, sans-serif'}}>TECH LEAD</div>
                        <div style={{fontSize:'16px', fontWeight:'550',fontFamily:'Plus Jakarta Sans, sans-serif'}}>Dhananjay Kumar</div>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={6} sm={3} md={3} >
                  <Item>
                    <Box>
                    <img style={{maxWidth:'100%'}} src='/team-4.jpg' alt='team-4'/>
                    </Box>
                    <Box style={{paddingTop:'8px'}}>
                        <div style={{fontFamily:'Plus Jakarta Sans, sans-serif'}}>REACT JS DEVELOPER</div>
                        <div style={{fontSize:'16px', fontWeight:'550',fontFamily:'Plus Jakarta Sans, sans-serif'}}>Ravish Kumar</div>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={6} sm={3} md={3} >
                  <Item>
                    <Box>
                        <img style={{maxWidth:'100%'}} src='/team-6.jpeg' alt='team-1'/>
                    </Box>
                    <Box style={{paddingTop:'8px'}}>
                        <div style={{fontFamily:'Plus Jakarta Sans, sans-serif'}}>SEO EXPERT</div>
                        <div style={{fontSize:'16px', fontWeight:'550', fontFamily:'Plus Jakarta Sans, sans-serif'}}>Sanjoli Thakur</div>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={6} sm={3} md={3} >
                  <Item>
                    <Box>
                        <img style={{maxWidth:'100%'}} src='/team-5.jpeg' alt='team-1'/>
                    </Box>
                    <Box style={{paddingTop:'8px'}}>
                        <div style={{fontFamily:'Plus Jakarta Sans, sans-serif'}}>FULL STACK DEVELOPER</div>
                        <div style={{fontSize:'16px', fontWeight:'550', fontFamily:'Plus Jakarta Sans, sans-serif'}}>SK Noor</div>
                    </Box>
                  </Item>
                </Grid>
                
            </Grid>
       
          </Box>
        
          </Container>
          </section>

            


        </>
    )
}
export default Superheroes;