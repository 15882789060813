import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Privacy from './Privacy'



const Privacypolicy=()=>
{
     return(
        <>
            
            <div className='content'>
                <Header/>
                <Privacy/>
                <Footer/>

            </div>
        </>
     )
}
export default Privacypolicy;
