import React from 'react'
import Header from '../Header/Header'
import Termsandcondition from './Termsandconditon'
import Footer from './Footer'

const Terms=()=>
{
     return(
        <> 
            <div className='content'>
                <Header/>
                <Termsandcondition/>
                <Footer/>

            </div>
        </>
     )
}
export default Terms;
