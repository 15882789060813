import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";


const Stories=()=>
{
  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const [isImageLoaded, setImageLoaded] = useState(false);

 useEffect(() => {
     const img = new Image();
     img.src = '/lavendel-about-us.png';
     img.onload = () => {
         setImageLoaded(true);
     };
 }, []);

      
 const [showContent, setShowContent] = useState(false);

 useEffect(() => {
     setShowContent(true);
 }, []);


    return (
        <>
          
           
          <section  style={{backgroundColor:'var(--secondary)'}} >
            <Container maxWidth='lg'>
           
              <Box style={{textAlign:'center'}} className='section'> 
           
             <h1 className='heading_title white' style={{margin: isSmscreen?'0px 0px 12px':'0px 0px 20px'}}>Trusted by over <span style={{fontWeight:'bold'}}>32+</span> businesses owners!</h1> 
           
            <h2 className="title white" style={{textAlign:'center',margin:'0px'}}>
            Our team has come together through the passion of solving complicated <br></br>
            problems, through design & development.
            </h2>

            </Box>

          </Container>
          </section>
   
            <section className="section">
            <Container maxWidth='lg'>

           <Box>
           <Grid container rowSpacing={2} columnSpacing={{xs:4,sm:6,md:8}} display="flex" justifyContent="space-between" alignItems="center" >
           

       <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent='center' >
      
       {showContent && (
        <div>
      
        <h1 className="page_title" style={{marginBottom:'0px'}}> The We Think <span className="bold">Story !</span> </h1>  

        <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="The WeThink Story"
                    loading="lazy"
                   
                />
                      
      
                    <div>
                        <p className="line-height">
                            We Think was founded to bring higher standards and professionalism to design. A fundamental difference between us and any other design agency is the way we approach design. We believe that a good design makes all the difference between user satisfaction and user delight. Our team is well versed in the language of business and software application development. In our most successful engagements. 
                        </p>
                        <p className="line-height">
                            We Think team becomes an integral part of the client’s team thinking, brainstorming and solving problems along with the client's team. Brand experience is what your customers remember about you. It’s what leads to lasting impressions, preferences, and performance.
                        </p>
                        <p className="line-height">
                            We build software solutions using emerging technologies for startups and enterprises. We have rich and sharp proficiency and extensive experience in User Experience Design, Custom App Development, Mobile app design, SaaS Development, Custom Software Development, Web Development and apps for all types of businesses.
                        </p>
                    </div>

        </div>
       )}
        </Grid>

        <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'none'} alignItems='center'>
       
          
          <LazyLoadImage
          src="/lavendel-about-us.png"
          alt="we think story"
          style={{
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: isImageLoaded ? 1 : 0, 
              transition: 'opacity 0.3s ease-in-out',
          }}
         
         />
          
          </Grid>


          <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex'>

                <div className="box-shadow common-box">
                
                <div>
                <p className="page_title" style={{margin:'0px'}}>30+</p>
                <p className="home_font" style={{margin:'0px'}}>Designs delivered</p>
                
                </div>

                <div>

                <p className="page_title" style={{margin:'0px'}}>658+</p>
                <p className="home_font" style={{margin:'0px'}}>Productive days</p>

                </div>

                <div>

                    <p className="page_title" style={{margin:'0px'}}>22+</p>
                    <p className="home_font" style={{margin:'0px'}}>Projects</p>

                    </div>                   

                </div>
                </Grid>

       
   </Grid>


            </Box>

          </Container>
          </section>

            


        </>
    )
}
export default Stories;