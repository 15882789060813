import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";



const Digitalmatters=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

  const matters={
    border:'1px solid var(--primary)',
    borderRadius:'6px',
    padding: isSmscreen?'24px 12px':'40px 20px ',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    gridRowGap:'10px'
  }   

    return(
        <> 

          <section className="section" style={{backgroundColor:'#FFFCEC'}}>
          <Container maxWidth='lg' >

          <Box>
          <div>
          <h3 className ="heading_title" style={{textAlign:'center',marginBlock:'0px'}}>Digital Marketing That Matters!</h3>
        </div>
          
          <Box>
            <Grid container display="flex" justifyContent="center" textAlign="center">
                <Grid item xs={12} sm={8} md={8}>
                <p className="title"  style={{fontWeight:400}}>
                  Digital marketing services are everywhere but do you know what works for you 
                  business? That's why we're are. From Web development to content marketing and
                  social media, our content wizards and digital strategists deliver solutions that
                  make a difference.
              </p>
               
                
              <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="Benefits of Choosing Us"
                    loading="lazy"
                   
                />

                </Grid>
            </Grid>
       
          </Box>

          <Box>
            <Grid container rowSpacing={2} columnSpacing={{sm:2,md:4}} display="flex" justifyContent="space-between" paddingTop={isSmscreen?'20px':'40px'}>
                
               <Grid item xs={12} sm={isSmscreen?8:4} md={4} >
                
                 <div style={matters}>
                 <LazyLoadImage
                className='msgimage'
                src="/megaphone.png"
                alt="msg"
                loading="lazy"
                
            />     
                <div className="footer_primary_title">Marketing & Awareness</div>

                <div className="footer_font" style={{textAlign:'center',fontWeight:'400'}}>Reach, educate and grow your business online.</div>

                 </div>

                </Grid>
                <Grid item xs={12} sm={isSmscreen?8:4} md={4} >
                 <div style={matters}>
               
                 <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='ellipsesimages'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
             
            />  
                 <LazyLoadImage
                className='faceimages'
                src="/monitor.png"
                alt="msg"
                loading="lazy"
              
            />     
            </div>
                <div className="footer_primary_title">Traffic & Leads</div>
                <div className="footer_font" style={{textAlign:'center',fontWeight:'400'}}>Drive traffic to website/app to achieve business goal.</div>

                 </div>
                </Grid>

                <Grid item xs={12} sm={isSmscreen?8:4} md={4} >

                 <div style={matters}>
                 
                 <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='ellipsesimages'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
              
            />  
                 <LazyLoadImage
                className='faceimages'
                src="/worldwide.png"
                alt="msg"
                loading="lazy"
             
            />     
            </div>    
                <div className="footer_primary_title">Wider Reach</div>
                <div className="footer_font" style={{textAlign:'center',fontWeight:'400'}}>Increase your market and get more customer through digital</div>
                 </div>

                </Grid>
            </Grid>
       
          </Box>


          </Box>

           </Container>
           </section>
        </>
    )
}

export default Digitalmatters;