import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Return from './Return'

const Returnpolicy=()=>
{
     return(
        <>
            
            <div className='content'>
                <Header/>
                <Return/>
                <Footer/>

            </div>
        </>
     )
}
export default Returnpolicy;