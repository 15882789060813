import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';



const Ecommerce=()=>
{

    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>

        <Helmet>

        <link rel="canonical" href={canonicalUrl} />
        </Helmet>
         
           <div className="containers">
            <div className="content">
            <Header/>

             <div>E-Commerce Solution</div>
         
            </div>
            <Footer/>
            </div>
            
         
        </>
    )
}

export default Ecommerce;