import React,{useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import { Box, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material"; 
import { motion } from "framer-motion"; 
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar'; 


const Wordpress=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
    

     const containerContent = {
      visible: { x: 0, opacity: 1, transition: { duration: 1 } },
      hidden: { x: -100, opacity: 0 }
    };
  
  

    const Item1 = styled(Paper)(({ theme }) => ({
     
     ...theme.typography.body2,
     padding:"40px",
     borderRadius:'6px',
     marginBottom:isSmscreen?"10px":"20px",
     boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',
   
   }));


   const customButton=
   {
 
   backgroundColor:'var(--primary)',
   color:'#000000',
 fontSize:'18px',
 padding:'12px 24px',
 fontWeight: 600,
 textTransform:'none',
 margin:isSmscreen?'10px 0px 20px':'20px 0px 30px',
 letterSpacing: '0.8px',  
   '&:hover': {
  backgroundColor:'var(--primary)',
   },
   }

   const customButtons=
   {
 
   backgroundColor:'var(--primary)',
   color:'var(--black)',
 fontSize:'18px',
 padding:'12px 24px',
 width:'100%',
 fontWeight: 600,
 textTransform:'none',
 margin:isSmscreen?'10px 0px 0px':'20px 0px 0px',
 letterSpacing: '0.8px',  
   '&:hover': {
  backgroundColor:'var(--primary)',
   },
   }

const Baseurl = process.env.REACT_APP_BASE_URL;

 const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });


 const [openSnackbar1, setopenSnackbar1]=useState({

   openSnackbar: false,
   vertical: 'top',
   horizontal: 'center',
  });


  const { vertical, horizontal, openSnackbar } = openSnackbar1;

  const handleClose = (reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setopenSnackbar1({...openSnackbar1, openSnackbar:false});

 };

 const [openSnackbar2, setopenSnackbar2]=useState({

   opentoast: false,
   verticaltoast: 'top',
   horizontaltoast: 'center',
  });


  const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;


  const handleCloseToast = (reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setopenSnackbar2({...openSnackbar2, opentoast:false});

 };

const [open, setopen]=useState(false)

const [lastClickedField, setLastClickedField] = useState(null);
const [apierror, setapierror]=useState()

const started=()=>
{
    setopen(true)
}

const [input,setinput]=useState({
   username:"",
   email:""
})

const [errors, setErrors] = useState()

const handleChanged=(e)=>
{
   const name= e.target.name;
   const value=e.target.value;

   const newErrors = {}

   if (name === 'username' && value.length === 0) {
     newErrors.username = 'This field is required'
   } else {
     newErrors.username = ''
   }
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 
   if (name === 'email' && value.length === 0) {
     newErrors.email = 'This field is required'
   }
   else if(name === 'email' && !emailPattern.test(value)){
        newErrors.email='Please enter valid email'
   }
   else {
     newErrors.email = ''
   }

   if (
     newErrors.username === '' &&
     newErrors.email === '' 
   ) {
     setErrors('')
   } else {
    
     setErrors(newErrors)
   }


   setinput({...input, [name]:value})
}




const handleSubmit=(newState)=>
{

   const apiurl = `${Baseurl}/signin`;

     const newErrors = {}

  if(input.username==="")
   {
       newErrors.username='This field is required'
   }
   else{
       newErrors.username=''
   }
  const newemail=input.email;

   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
   if(input.email.length===0)
   {
       newErrors.email='This field is required'
   }

  else  if (!emailPattern.test(newemail)) {
  
      newErrors.email='Please enter valid email'
  }
  else
  {
      newErrors.email=''
  }

   if (
     newErrors.username === '' &&
     newErrors.email === '' 
   ){
       
     const formdata = new FormData();

       formdata.append("name", input.username);
       formdata.append("email", input.email);
       formdata.append("page_name", "Web Services");

       const requestOptions = {
         method: "POST",
         body: formdata,
         redirect: "follow"
       };

       fetch(`${apiurl}`, requestOptions)
         .then((response) => response.text())
         .then((result) => {

           const data= JSON.parse(result);

           if(data.status==='success')
           {

             setopenSnackbar1({ ...newState, openSnackbar: true });
             setErrors({})
             setopen(false)
             setinput({username:"", email:"",})
             
           

           }
           else 
           {
              setapierror('There is some technical issue!')
              setopenSnackbar2({ ...newState, opentoast: true });
           }

         })
         .catch((error) => console.error(error));


   }else {
    
     setErrors(newErrors)
   }


}

const cancel=()=>
{
   setopen(false)
}


const handleFocus = (fieldName) => {
   setLastClickedField(fieldName);
 };



 const [isImageLoaded, setImageLoaded] = useState(false);
 const [showContent, setShowContent] = useState(false);

 useEffect(() => {
     const img = new Image();
     img.src = '/Why Should you choose 5.png';
     img.onload = () => {
         setImageLoaded(true);
         setShowContent(true);
     };
 }, []);





    return(
        <>

     
          <section className="section" style={{background:'#18143B'}} >
      
            <Container maxWidth='lg'>
     
            <Box>
             <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:isSmscreen?4:6,md:6}} display="flex" justifyContent="space-between" alignItems='center' >
           
          
         <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent='center'>
       
         {showContent && (
         <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerContent}
              viewport={{ once: false}}
              style={{overflowX:'hidden'}}
          >
  
          <h1 className="hero_title white" style={isSmscreen? {paddingTop:'20px'}:{}}>
            Web Services
          </h1>
          <h2 className="secondary_title white">
           Your dream deserves more than a place 
           in your imagination

          </h2>

          
        <Button style={customButton} onClick={started} className="primary_button">Get Started</Button>
          
          </motion.div>
         )}
          </Grid>
    
         
  
              <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'flex-end'} style={isSmscreen?{paddingBottom:'30px'}:{}} >
             
              <LazyLoadImage
                  src="/Why Should you choose 5.png"
                  alt="Why Should you choose"
                  loading="lazy"
                  style={{
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      opacity: isImageLoaded ? 1 : 0, 
                      transition: 'opacity 0.3s ease-in-out',
                  }}
                
              />
  
         </Grid>
         
     </Grid>

     <Modal open={open}> 
                           <Item1 className="ux_ui_design">
                            <form>
                            <div className="edit_data">      
             <div className="title" style={{fontWeight:'600'}}>Web Services</div>
             <div style={{cursor:'pointer'}} onClick={cancel}><CloseIcon/></div>
             </div>

             <p className="footer_font" style={{marginBottom:'0px'}}>Fill up the form to connect with our team</p>

              <Box  noValidate  autoComplete="off"  >
             
              <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:4,md:6}} display="flex" justifyContent="space-between" alignItems="center" padding={isSmscreen?"8px 0px 20px":"8px 0px 20px"} >
           

           <Grid item xs={12} sm={isSmscreen?12:6} md={6}  >

              
               <div style={{paddingBottom:"8px"}}>
              <label htmlFor="personal" className="footer_font">Name</label></div>
   
         <div>
             
              <TextField id="first_name" label="Name" value={input.username}   
              name="username"   onChange={handleChanged} 
              autoFocus={lastClickedField === 'username'}
              onFocus={() => handleFocus('username')}
              style={{width:'100%'}}
              className="footer_font"
              error={Boolean(errors?.username)}
              helperText={errors?.username} />
            </div>
   
              </Grid>
  
            
           <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
            
             <div style={{paddingBottom:"8px"}}>

             <label htmlFor="email" className="footer_font">Email Address</label>
         </div>
           
             <div>
              <TextField id="email" type="email" 
              label="Email Address" value={input.email}  
              name="email" onChange={handleChanged}
              autoFocus={lastClickedField === 'email'}
              style={{width:'100%'}}
              className="footer_font"
              onFocus={() => handleFocus('email')}    
              error={Boolean(errors?.email)}
              helperText={errors?.email} />
             </div>

      
        
             </Grid>
             </Grid>
                 
              </Box>
         
              <div className="save_profile">
          <Button style={customButtons}  className="primary_button" target="_blank" onClick={()=>handleSubmit({vertical: 'top', horizontal: 'center' })}>Download</Button></div>
            </form>
            </Item1> 
                   </Modal>

        
              </Box>

              <Stack spacing={2} sx={{ width: '100%' }}>
  
  <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
  <Alert  severity="success" sx={{ width: '100%' }}>
          Thank you for your submitting. Will get contact soon shortly.
          </Alert>
      </Snackbar>

  </Stack>  

  <Stack spacing={2} sx={{ width: '100%' }}>

      <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
      <Alert  severity="error" sx={{ width: '100%' }} >
          {apierror}
          </Alert>
      </Snackbar>

  </Stack>

  
              </Container>

           </section>
        </>
    )
}

export default Wordpress;

