import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";


const Niches=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

    return(
        <> 

          <section className="section">
          <Container maxWidth='lg' >

          <Box>
          <div>
          <h3 className ="heading_title" style={{textAlign:'center',margin:'0px'}}>We Build Mobile Solutions for all Niches</h3>
        </div>
          
        <Box>
            <Grid container display="flex" justifyContent="center" textAlign="center">
                <Grid item xs={12} sm={8} md={8} >
                <p className="title"   style={isSmscreen? {fontWeight:'400'}:{padding:'0px 0px 44px',fontWeight:'400',lineHeight:'26px'}}>
                 No matter under which niche your project falls, with the help of our experienced mobile app
                 developer, we can deliver you with robust mobile application solutions.
              </p>

                </Grid>
            </Grid>
       
          </Box>

          <Box style={{paddingTop:'20px'}}>
            <Grid container columnSpacing={{xs:2,sm:3,md:4}} rowSpacing={{xs:6,sm:8,md:10}} display="flex" justifyContent="center">
                <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}} >
                 
                   <div className="niches">
                   <LazyLoadImage
                className='aidimage'
                src="/firstaid.png"
                alt="msg"
                loading="lazy"
              /> 
              <div className="title"  style={{fontWeight:700}}>Healthcare</div>

                   </div>

                </Grid>
                <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}} >
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/box.png"
              alt="msg"
              loading="lazy"
          
            /> 
            <div className="title" style={{fontWeight:700}}>Logistics</div>

                 </div>

              </Grid>
              <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}} >
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/education.png"
              alt="msg"
              loading="lazy"
           
            /> 
            <div className="title" style={{fontWeight:700}} >Education</div>

                 </div>

              </Grid>
              <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}} >
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/avatar.png"
              alt="msg"
              loading="lazy"
            
            /> 
            <div className="title" style={{fontWeight:700}}>Retail</div>

                 </div>

              </Grid>
              <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}}>
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/dollar.png"
              alt="msg"
              loading="lazy"
             
            /> 
            <div className="title" style={{fontWeight:700}}>Finance</div>

                 </div>

              </Grid>
              <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}} >
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/heart.png"
              alt="msg"
              loading="lazy"
           
            /> 
            <div className="title"  style={{fontWeight:700}}>Wellness & Fitness</div>

                 </div>

              </Grid>
              <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}} >
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/pump.png"
              alt="msg"
              loading="lazy"
           
            /> 
            <div className="title"  style={{fontWeight:700}} >Oil & Gas</div>

                 </div>

              </Grid>
              <Grid item xs={6} sm={isSmscreen?6:3} md={3} style={{textAlign:'center'}}>
                 
                 <div className="niches">
                 <LazyLoadImage
              className='aidimage'
              src="/travel.png"
              alt="msg"
              loading="lazy"
         
            /> 
            <div className="title"  style={{fontWeight:700}} >Transportation</div>

                 </div>

              </Grid>
            </Grid>
       
          </Box>
          </Box>
           </Container>
           </section>
        </>
    )
}

export default Niches;