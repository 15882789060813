import React,{useState} from "react";
import { Box, Container, Grid ,Button} from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Premium=()=>
{
 
  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const Item1 = styled(Paper)(({ theme }) => ({
      
  ...theme.typography.body2,
  padding:"40px",
  borderRadius:'6px',
  marginBottom:isSmscreen?"10px":"20px",
  boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',

}));
 
 const customButton=
 {

 backgroundColor:'var(--primary)',
 color:'#000000',
fontSize:'18px',
padding:'12px 24px',
borderRadius:'10px',
fontWeight: 600,
textTransform:'none',
margin:isSmscreen?'20px 0px 20px':'30px 0px 30px',
width:'100%', 
 '&:hover': {
backgroundColor:'var(--primary)',
 },
 }

 const customButtons=
 {

 backgroundColor:'var(--primary)',
 color:'#000000',
fontSize:'18px',
padding:'12px 24px',
borderRadius:'10px',
fontWeight: 600,
textTransform:'none',
margin:isSmscreen?'20px 0px 0px':'30px 0px 0px',
width:'100%', 
 '&:hover': {
backgroundColor:'var(--primary)',
 },
 }


 const Baseurl = process.env.REACT_APP_BASE_URL;

 const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });


 const [openSnackbar1, setopenSnackbar1]=useState({

   openSnackbar: false,
   vertical: 'top',
   horizontal: 'center',
  });


  const { vertical, horizontal, openSnackbar } = openSnackbar1;

  const handleClose = (reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setopenSnackbar1({...openSnackbar1, openSnackbar:false});

 };

 const [openSnackbar2, setopenSnackbar2]=useState({

   opentoast: false,
   verticaltoast: 'top',
   horizontaltoast: 'center',
  });


  const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;


  const handleCloseToast = (reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setopenSnackbar2({...openSnackbar2, opentoast:false});

 };

const [open, setopen]=useState(false)

const [lastClickedField, setLastClickedField] = useState(null);
const [apierror, setapierror]=useState();

const [website, setwebsite]=useState()

const started=(website)=>
{
    setwebsite(website)
    setopen(true)
}

const [input,setinput]=useState({
   username:"",
   email:""
})

const [errors, setErrors] = useState()

const handleChanged=(e)=>
{
   const name= e.target.name;
   const value=e.target.value;

   const newErrors = {}

   if (name === 'username' && value.length === 0) {
     newErrors.username = 'This field is required'
   } else {
     newErrors.username = ''
   }
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 
   if (name === 'email' && value.length === 0) {
     newErrors.email = 'This field is required'
   }
   else if(name === 'email' && !emailPattern.test(value)){
        newErrors.email='Please enter valid email'
   }
   else {
     newErrors.email = ''
   }

   if (
     newErrors.username === '' &&
     newErrors.email === '' 
   ) {
     setErrors('')
   } else {
    
     setErrors(newErrors)
   }


   setinput({...input, [name]:value})
}


const handleSubmit=(newState)=>
{

   const apiurl = `${Baseurl}/signin`;

     const newErrors = {}

  if(input.username==="")
   {
       newErrors.username='This field is required'
   }
   else{
       newErrors.username=''
   }
  const newemail=input.email;

   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
   if(input.email.length===0)
   {
       newErrors.email='This field is required'
   }

  else  if (!emailPattern.test(newemail)) {
  
      newErrors.email='Please enter valid email'
  }
  else
  {
      newErrors.email=''
  }

   if (
     newErrors.username === '' &&
     newErrors.email === '' 
   ){
       
     const formdata = new FormData();

       formdata.append("name", input.username);
       formdata.append("email", input.email);
       formdata.append("page_name", website);

       const requestOptions = {
         method: "POST",
         body: formdata,
         redirect: "follow"
       };

       fetch(`${apiurl}`, requestOptions)
         .then((response) => response.text())
         .then((result) => {

           const data= JSON.parse(result);

           if(data.status==='success')
           {

             setopenSnackbar1({ ...newState, openSnackbar: true });
             setErrors({})
             setopen(false)
             setinput({username:"", email:"",})
             
           

           }
           else 
           {
              setapierror('There is some technical issue!')
              setopenSnackbar2({ ...newState, opentoast: true });
           }

         })
         .catch((error) => console.error(error));


   }else {
    
     setErrors(newErrors)
   }


}

const cancel=()=>
{
   setopen(false)
}


const handleFocus = (fieldName) => {
   setLastClickedField(fieldName);
 };
    



    return (
        <>
          
           
           
          <section className="section">
            <Container maxWidth='lg'>
           
        
          
          <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="center" alignItems='center'>

            <Grid item xs={12} sm={isSmscreen?12:8} md={8} >
             
               <div className='superheroes'>

               <h1 className="page_title" style={{marginBottom:'0px'}}> Word press Website Design Packages </h1>  
               <p className="line_height title text-center" style={{margin:'12px 0px 18px'}}>We are an industry-leading company that values honesty, integrity, and efficiency 
                  Buliding quality products and caring </p>

                  <div style={{display:'flex',justifyItems:'center'}}>
                 <LazyLoadImage
               
                    src="/Rectangle.svg"
                    alt="Premium"
                    loading="lazy"
                   
                />
               </div>

               </div>
           
             
              </Grid>
              </Grid>
              <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display='flex' justifyContent='space-between' style={{paddingTop:isSmscreen?'40px':'80px'}} >
                   <Grid item xs={12} sm={isSmscreen?12:8} md={4}>
                     <Box className='premium'>
                       <Box className='section_title dynamic'>Static Website</Box>
                       <Box className='dynamic_label'>Absolute Solution to the small businesses or Individuals.</Box>
                         
                       <Button style={customButton} onClick={()=>started('Static Website')}>Get Started</Button>

                         <ul className="item-list home_font" style={{listStyleType:'none',paddingLeft:'0'}}>
                           <li><ChevronRightRoundedIcon/>&nbsp;Home page</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Services</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Gallery</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;About us</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Contact us</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;FREE 1 Month Maintenance</li>
                         </ul>

                       
                     

                     </Box>
                   </Grid>

                   <Grid item xs={12} sm={isSmscreen?12:8} md={4}>
                     <Box className='premium'>
                     <div className="badge">Most popular</div>
                     <div className="badges"></div>
                       <Box className='section_title dynamic' >
                        Dynamic Website

                       </Box>
                 
                       <Box className='dynamic_label'>Innovative Web design services to large businesses</Box>
                      
                       <Button style={customButton} onClick={()=>started('Dynamic Website')}>Get Started</Button>

                       <ul className="item-list home_font" style={{paddingBottom:'10px',listStyleType:'none',paddingLeft:'0'}}>
                            <li><ChevronRightRoundedIcon/>&nbsp;Home page</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Services</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Gallery</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;About us</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Contact us</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Careers page</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Forms (Limited)</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Register/Sign option</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;FREE 1 Month Maintenance</li>
                         </ul>
                         
                      

                     </Box>
                   </Grid>

                   <Grid item xs={12} sm={isSmscreen?12:8} md={4}>
                     <Box className='premium'>
                       <Box className='section_title dynamic'>E-commerce Website</Box>
                       <Box className='dynamic_label'>Growing online visibility and sales for large B2B and B2C companies.</Box>
                            
                       <Button style={customButton} onClick={()=>started('E-commerce Website')}>Get Started</Button>

                       <ul className="item-list home_font" style={{paddingBottom:'10px',listStyleType:'none',paddingLeft:'0'}}>
                              <li><ChevronRightRoundedIcon/>&nbsp;Online Store</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Checkout Page</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Product Details Page</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Inventory Management</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Order Management</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Payment gateway integration</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Premium Theme</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Enquiry Form integration</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;FREE 1 Month Maintenance</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Free SSL and Hosting</li>
                         </ul>

                         
                         

                     </Box>
                   </Grid>

                </Grid>

                <Modal open={open}> 
                           <Item1 className="ux_ui_design">
                            <form>
                            <div className="edit_data">      
             <div className="title" style={{fontWeight:'600'}}>{website}</div>
             <div style={{cursor:'pointer'}} onClick={cancel}><CloseIcon/></div>
             </div>

             <p className="footer_font" style={{marginBottom:'0px'}}>Fill up the form to connect with our team</p>

              <Box  noValidate  autoComplete="off"  >
             
              <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:4,md:6}} display="flex" justifyContent="space-between" alignItems="center" padding={isSmscreen?"8px 0px 20px":"8px 0px 20px"} >
           

           <Grid item xs={12} sm={isSmscreen?12:6} md={6}  >

              
               <div style={{paddingBottom:"8px"}}>
              <label htmlFor="personal" className="footer_font">Name</label></div>
   
         <div>
             
              <TextField id="first_name" label="Name" value={input.username}   
              name="username"   onChange={handleChanged} 
              autoFocus={lastClickedField === 'username'}
              onFocus={() => handleFocus('username')}
              style={{width:'100%'}}
              className="footer_font"
              error={Boolean(errors?.username)}
              helperText={errors?.username} />
            </div>
   
              </Grid>
  
            
           <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
            
             <div style={{paddingBottom:"8px"}}>

             <label htmlFor="email" className="footer_font">Email Address</label>
         </div>
           
             <div>
              <TextField id="email" type="email" 
              label="Email Address" value={input.email}  
              name="email" onChange={handleChanged}
              autoFocus={lastClickedField === 'email'}
              style={{width:'100%'}}
              className="footer_font"
              onFocus={() => handleFocus('email')}    
              error={Boolean(errors?.email)}
              helperText={errors?.email} />
             </div>

      
        
             </Grid>
             </Grid>
                 
              </Box>
         
              <div className="save_profile">
          <Button style={customButtons}  className="primary_button" target="_blank" onClick={()=>handleSubmit({vertical: 'top', horizontal: 'center' })}>Download</Button></div>
            </form>
            </Item1> 
                   </Modal>


       
          </Box>
          <Stack spacing={2} sx={{ width: '100%' }}>
  
  <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
  <Alert  severity="success" sx={{ width: '100%' }}>
          Thank you for your submitting. Will get contact soon shortly.
          </Alert>
      </Snackbar>

  </Stack>  

  <Stack spacing={2} sx={{ width: '100%' }}>

      <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
      <Alert  severity="error" sx={{ width: '100%' }} >
          {apierror}
          </Alert>
      </Snackbar>

  </Stack>

          

          </Container>
          </section>

            


        </>
    )
}
export default Premium;

/* <LazyLoadImage
            style={{maxWidth:'100%'}}
                    src="/mission-vision.png"
                    alt="The WeThink Story"
                    loading="lazy"
                />
                */