import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, createTheme, useMediaQuery,Grid } from "@mui/material";
import { Container } from "@mui/material";

const Uxdevelopment=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))


    return (
        <>
               <section className="section">
            <Container maxWidth='lg'>

            <Box> 
          
            <Grid container display="flex" justifyContent='center'>
                <Grid item xs={12} sm={12} md={12}>
                   
                 
                <span className="page_title text-center" style={{position:'relative'}}>
             
             

            <p  style={{margin:'0px'}}>"We Think is a <span className='bold'>UI/UX</span> development company specializing in handcrafting beautiful designs that add value and creates a brand identity."
            
           
             </p>

             </span> 

             <p className='line-height home_font text-center'>By focusing on user behaviour and their interaction pattern with applications, our UI/UX services transcend beyond just the aesthetics to being user centric and content centric. To build hyper-personalized experiences, we deliver a user experience to improve engagement, usability, and brand recall.</p>

              <p className='line-height home_font text-center' style={{position:'relative'}}>Our team of UX strategists, information architects, and visual designers look at the larger constellation of touchpoints with audiences to craft innovative UI/UX experiences.
            
            </p>
            <div style={{display:"flex",justifyContent:'center', padding:'8px 0px'}}>
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="UX-Design"
                    loading="lazy"
                  
                />
                   </div>
                </Grid>
            </Grid>
        
            
                
            </Box>  
           

            </Container>
            </section>
        </>
    )
}

export default Uxdevelopment;

/* <LazyLoadImage
               
               className='ellipsesimage upper_circle'
               src="/ellipses.png"
               alt="msg"
               loading="lazy"
           /> 
           
           
             <LazyLoadImage
                className='ellipsesimage lower_circle'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
            />
           */