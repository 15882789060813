import React from "react";
import Grid from '@mui/material/Grid';
import { Box, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
       


const Digitalservices=()=>
{
    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
 

    return(
        <> 

          <section className="section section">
          <Container maxWidth='lg' >
          <Box>
           <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:isSmscreen?4:6,md:6}} display="flex" justifyContent="space-between" alignItems='center' >

               
       <Grid item xs={12} sm={isSmscreen?12:6} md={6}  >
        
        <div className="branding">

         <div className="flex">
     
            <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='webimage'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
            
            />  
                 <LazyLoadImage
                className='brandimage'
                src="/branding.png"
                alt="msg"
                loading="lazy"
          
            />     
            </div>
          
            <div className="footer_primary_title" style={{fontWeight:'700'}}>
                Branding
            </div>

            </div>



            <div className="flex">

            <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='webimage'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
               
            />  
                 <LazyLoadImage
                className='brandimage'
                src="/googleweb.png"
                alt="msg"
                loading="lazy"
            
            />     
            </div>

          
            <div className="footer_primary_title" style={{fontWeight:'700'}}>
                Search Engine Optimization
            </div>

            </div>

            <div className="flex">

           
            <LazyLoadImage
                className='messagingimage'
                src="/messaging.png"
                alt="msg"
                loading="lazy"
            
            />
           
            <div className="footer_primary_title" style={{fontWeight:'700'}}>
                Content & Marketing
            </div>
             </div>


            <div className="flex">
            <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='webimage'
                src="/ellipses.png"
                alt="msg"
            
            />  
                 <LazyLoadImage
                className='brandimage'
                src="/email.png"
                alt="msg"
                loading="lazy"
               
            />     
            </div>
           
            <div className="footer_primary_title" style={{fontWeight:'700'}}>
                Email Marketing
            </div>
            </div>


            <div className="flex">
            <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='webimage'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
               
            />  
                 <LazyLoadImage
                className='brandimage'
                src="/web.png"
                alt="msg"
                loading="lazy"
             
            />     
            </div>
          
            <div className="footer_primary_title" style={{fontWeight:'700'}}>
                Web Development
            </div>
            </div>

            <div className="flex">
            <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='webimage'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
           
            />  
                 <LazyLoadImage
                className='brandimage'
                src="/video.png"
                alt="msg"
                loading="lazy"
            
            />     
            </div>
         
            <div className="footer_primary_title" style={{fontWeight:'700'}}>
                Video Marketing
            </div>
            </div>

        </div> 

      
        </Grid>

            <Grid item xs={12} sm={isSmscreen?12:6} md={6}  style={isSmscreen?{paddingBottom:'30px'}:{}} >
           
             
            <div className="heading_title" style={isSmscreen? {paddingTop:'20px'}:{}}>
           Our Digital Marketing Services
        </div>
        <p className="title"  style={{fontWeight:400}}>
         In order to make a strong digital presence, it is recommended to have 
         traditional marketing strategies to exist. However, businesses are promoting
         their services and products through online media by making a tremendous profit than
         traditional marketing. To achieve that, we use different digital marketing channels.
         Those approaches are chosen according to what suits best for your requirements.
        </p>
        <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="Benefits of Choosing Us"
                    loading="lazy"
                />
     
       </Grid>
       
   </Grid>
      
            </Box>

           </Container>
           </section>
        </>
    )
}

export default Digitalservices;