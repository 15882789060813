export   const Accordian = [
    {
           title:'Semantic  Search ',
           label:' When developing a website, we involve our customer at every stage of the process. We follow an agile approach and encourage feedback allowing us to tweak the product until customer’s satisfaction. Our methodological approach has helped us work with some great clients and add.',
           image:'/Why Should you choose 1.png'
    },
    {
        title:'Stable & Innovative',
        label:' Working to better the industry and the experience for those taking part is a key driver for the team here at We think.',
        image:'/Why Should you choose 2.png'
    },
    
    {
        title:'Cost-effective',
        label:' We have helped our customers register their products in virtually all relevant markets and can help optimize the submission process. This can dramatically reduce the cost and the time-to-market for your product.',
        image:'/Why Should you choose 3.png'
    },
    {
        title:'Plugins for functionalities',
        label:'You can then edit your functionality plugin and add snippets to it using the quick link in the admin menu.',
        image:'/Why Should you choose 4.png'
    },
    {
        title:'Content Updating',
        label:'Always updated content has numerous benefits. Much like evergreen content, it is similar in that it remain consistently relevant and continually generate returns. When it comes down to it, every business is looking for growth and long-term sustainability and we want to be a part of it in your success story.',
        image:'/Why Should you choose 5.png'
    }

]