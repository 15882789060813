import React from "react";
import { Container } from "@mui/material";

const Return=()=>
{
    return(
        <>
                   <section style={{backgroundColor:'var(--secondary)'}}>
           
            <Container maxWidth='lg'>
               <h1 className="hero_title text-center white" style={{marginBlock:'0px',paddingBlock:'80px'}}>Return<span style={{color:'var(--primary)'}}> Policy</span></h1>
               </Container>
            </section>

            <section className="section">
            <Container maxWidth='lg'>

        <span className="primary_title semibold mb-40"> 23rd of December, 2020</span>
        <div>

        <p className="primary_title line-height">Shopping</p>
            <span className="mt-1 mb-1 home_font line-height">
                After completion of payment we will ship/deliver the software services to your email id. 
                As it's a digital product, we will be sending all details to the email.
            </span>


            <span className="mt-1 mb-1 home_font line-height">
                Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase for any reason, you may return it to us for 
            </span>

            <p className="primary_title line-height">RETURN POLICY</p>
         
            
            <span className="bold mt-2 mb-2 home_font line-height">Last updated December 01, 2022</span> <br></br>
            
            <span className="home_font line-height mt-1 mb-1">
                Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase for any reason, you may return it to us for 
            </span>&nbsp;
            
            <span className="bold mt-2 mb-2 home_font line-height">Returns</span>&nbsp; 
            <span className="home_font line-height mt-1 mb-1">
                All returns must be postmarked within seven (7) days of the purchase date. <br></br> 
                All returned items must be in new and unused condition, 
                with all original tags and labels attached.            
            </span>


            <p className="primary_title line-height">Return Process</p>
            
            <span className="font-14 w-100 home_font line-height">
                To return an item, place the item securely in its original packaging and include 
                your proof of purchase, then mail your return to the following address: <br></br>
                PARASHAR CREATIVE STUDIO<br></br>
                Attn: Returns<br></br>
                Gunjur-Doddakannelli Rd, Kachamaranahalli,<br></br>
                Bangalore, 560087 560087<br></br>
            </span>

            <p className="primary_title line-height">REFUNDS</p>
          

            <span className="home_font line-height">                
                After receiving your return and inspecting the condition of your item, we will
                process your return. <br></br><br></br>
                Please allow at least seven (7) days from the receipt of
                your item to process your return. Refunds may take 1-2 billing cycles to appear
                on your credit card statement, depending on your credit card company. We will
                notify you by email when your return has been processed.
                    
            </span>

            <p className="primary_title line-height">EXCEPTIONS</p>

            <span className="home_font line-height mt-1 mb-1">    
                For defective or damaged products, please contact us at the contact details
                below to arrange a refund or exchange.
            </span><br></br>

            <span className="home_font line-height mt-1 mb-1">
                <span className="bold mt-2 mb-2 home_font line-height"> Please Note:&nbsp;
                     Sale items are FINAL SALE and cannot be returned.
                </span> <br></br>

            <span className="home_font line-height mt-1 mb-1">
                If you have any questions concerning our return policy, please contact us at: <br></br>
                    support@wethink.co.in<br></br>
            </span>
            
            </span>

        </div>

    </Container>
</section>


           
          
        </>
    )
}

export default Return;

/*
            
          
            
         
            */