import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import About from './About/About';
import Blog from './Blogs/Blogs';
import Contact from './Contact/Contact';
import Readblog from './Blogs/Readblog';
import Services from './Services/Services';
import Mobileapp from './Services/Mobileapp/Mobileapp';
import Digitalmarketing from './Services/Digitalmarketing/Digitalmarketing';
import UXdesign from './Services/Uxdesign/Uxdesign';
import Saasproduct from './Services/Saasproduct/Saasproduct';
import Ecommerce from './Services/Ecommerce/Ecommerce';
import Webservices from './Services/Webservices/webservices';
import Terms from './Footer/Terms';
import Privacypolicy from './Footer/Privacypolicy';
import Returnpolicy from './Footer/Returnpolicy';
import Whitepaper from './Resources/Whitepaper/Whitepaper';
import Casestudy from './Resources/Casestudy/Casestudy';
import Wordpressservice from './Services/Wordpressservices/webservices';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//  <React.StrictMode>
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App/>}/>
    <Route path="/about-us" element={<About/>}/>
    <Route path="/our-expertise" element={<Services/>}/>
    <Route path="/our-expertise/mobile-app-development" element={<Mobileapp/>}/>
    <Route path="/our-expertise/digital-marketing" element={<Digitalmarketing/>}/>
    <Route path="/our-expertise/web-services" element={<Webservices/>}/>
    <Route path="/our-expertise/wordpress-website-develomemt" element={<Wordpressservice/>}/>
    <Route path="/our-expertise/e-commerce-solution" element={<Ecommerce/>}/>
    <Route path="/our-expertise/saas-product-development" element={<Saasproduct/>}/>
    <Route path="/our-expertise/ui-ux-design-services" element={<UXdesign/>}/>
    <Route path="/white-paper" element={<Whitepaper/>}/>
    <Route path="/case-study" element={<Casestudy/>}/>
    <Route path="/blogs" element={<Blog/>}/>
    <Route path="/blogs/:id" element={<Readblog/>}/>
    <Route path="/contact-us" element={<Contact/>}/>
    <Route path="/terms-and-conditions" element={<Terms/>}/>
    <Route path="/privacy-policy" element={<Privacypolicy/>}/>
    <Route path="/return-policy" element={<Returnpolicy/>}/>


  </Routes>
  </BrowserRouter>
   
 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*   <Route path="/addblog" element={<Addblog/>}/>*/