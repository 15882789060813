
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import React, {useState} from "react";
import { Timeline, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

const Help=()=>
{


  const Baseurl = process.env.REACT_APP_BASE_URL;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };

  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;


   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

  const [user, setuser] = useState(
    {
        username:"",
        phone:"",
        email:"",
        about:"",
        message:""
    }
  )



  const [selectedCountry, setSelectedCountry] = useState('Web Services');


  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const [errors, setErrors] = useState()

  const [apierror, setapierror]=useState()

  const handleinput=(e)=>
  {
      const name= e.target.name;
      const value=e.target.value;

      const newErrors = {}
  
      if (name === 'username' && value.length === 0) {
        newErrors.username = 'This field is required'
      } else {
        newErrors.username = ''
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
  

      if (name === 'phone' && value.length === 0) {
        newErrors.phone = 'This field is required'
      } 

      else if(name === 'phone' && value.length !== 10) {
        newErrors.phone='Please enter valid phone number'
      }
      else {
        newErrors.phone = ''
      }

      if (name === 'email' && value.length === 0) {
        newErrors.email = 'This field is required'
      }
      else if(name === 'email' && !emailPattern.test(value)){
           newErrors.email='Please enter valid email'
      }
      else {
        newErrors.email = ''
      }

      if (name === 'message' && value.length === 0) {
        newErrors.message = 'This field is required'
      } else {
        newErrors.message = ''
      }
  
  
      if (
        newErrors.username === '' &&
        newErrors.phone === '' &&
        newErrors.email=== '' &&
        newErrors.message === ''
      ) {
        setErrors('')
      } else {
       
        setErrors(newErrors)
      }
  

      setuser({...user, [name]:value})
  }

  const handlesubmit=(e,newState)=>
  {

   const apiurl = `${Baseurl}/signin`;

     e.preventDefault();
     
     const newErrors={}

    if(user.username==="")
    {
        newErrors.username='This field is required'
    }
    else{
        newErrors.username=''
    }
    
  

    if(user.phone.length===0)
    {
        newErrors.phone='This field is required'
    }
    else if(user.phone.length!==10)
    {
         newErrors.phone='Please enter valid phone number'
    }
    else
    {
        newErrors.phone=''
    }

    const newemail=user.email;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   
    if(user.email.length===0)
    {
        newErrors.email='This field is required'
    }

   else  if (!emailPattern.test(newemail)) {
   
       newErrors.email='Please enter valid email'
   }
   else
   {
       newErrors.email=''
   }

   if (user.message.length==0)
   {
      newErrors.message='This field is required'
   }
   else
   {
     newErrors.message=''
   }

   if (
      newErrors.username === '' &&
      newErrors.email === '' &&
      newErrors.phone === '' &&
      newErrors.message === '' 
  )
    {
        
      const formdata = new FormData();

        formdata.append("name", user.username);
        formdata.append("email", user.email);
        formdata.append("phone", user.phone);
        formdata.append("message", user.message);
        formdata.append("page_name", "contact-us");

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow"
        };

        fetch(`${apiurl}`, requestOptions)
          .then((response) => response.text())
          .then((result) => {

            const data= JSON.parse(result);

            if(data.status==='success')
            {

              setopenSnackbar1({ ...newState, openSnackbar: true });
              setErrors({})
              setuser({username:"", phone:"", email:"", message:"",
        })
            }

            else 
            {
               setapierror('There is some technical issue!')
               setopenSnackbar2({ ...newState, opentoast: true });
            }

          })
          .catch((error) => console.error(error));


    }
    else {
       
        setErrors(newErrors)
      }
 
  }

    return(
        <>

           <section className="section">
           <Container maxWidth="lg">
         
            <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={2} columnSpacing={{ sm:4,md:6}}  display="flex" justifyContent="space-between" >
            <Grid item xs={12} sm={4} md={4}>
            <h3 className="heading_title  offers">You are in Good hands !</h3>
            <Timeline       sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: 'var(--primary)' }}/>
                <TimelineConnector sx={{ backgroundColor: 'black' }}/>
              </TimelineSeparator>
              <TimelineContent style={{fontWeight:'500', fontSize:'16px',fontFamily:'var(--font-family)'}}>Process driven UX design approach, 
                grounded in cognitive psychology
                 and behavorial science.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: 'var(--primary)' }}/>
                <TimelineConnector sx={{ backgroundColor: 'black' }} />
              </TimelineSeparator>
              <TimelineContent style={{fontWeight:'500', fontSize:'16px',fontFamily:'var(--font-family)'}}>Outcome Based Delivery.
                Proven track record of successfully leading 100+ 
                UX design projects.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: 'var(--primary)' }}/>
              </TimelineSeparator >
              <TimelineContent style={{fontWeight:'500', fontSize:'16px',fontFamily:'var(--font-family)'}}>Led by established UX design 
                & Innovation leaders. 
                60,000+ hours of combined global
                 UX consulting experience. </TimelineContent>
            </TimelineItem>
          </Timeline>

              </Grid>
              <Grid item xs={12} sm={8} md={8}>

               <div className="section_title offers" style={{margin:'15px 0px'}}>Let’s Build winning web experiences Together !</div>
               
               <form onSubmit={(e)=>handlesubmit(e,  {vertical: 'top', horizontal: 'center' })} className="form">
              <Box sx={{width:"100%"}}>

              <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3, md:4}} columnGap={{xs:2, sm:0, md:0}} display="flex" justifyContent="center" >
            
              <Grid item xs={12} sm={6} md={6} >
            
             <div className="form_title">
              <label className="label-form" htmlFor="username">Full name <span style={{color:"red"}}>*</span></label>
       
                <TextField className="input-form" type="text" value={user.username} name="username" onChange={handleinput}
                 placeholder="Devid Wonder"
                 error={Boolean(errors?.username)}
                 helperText={errors?.username} />
                </div>
                </Grid>

                <Grid item  xs={12} sm={6} md={6}>
                <div className="form_title">
                <label className="label-form" htmlFor="phone">Phone number <span style={{color:"red"}}>*</span></label>

                <TextField className="input-form" type="number" value={user.phone} name="phone" onChange={handleinput}
                 placeholder="+91 9876543210" 
                 error={Boolean(errors?.phone)}
                 helperText={errors?.phone} />
                </div>
                </Grid>

                <Grid item  xs={12} sm={6} md={6}>
                <div className="form_title">
                <label className="label-form" htmlFor="email">Email address <span style={{color:"red"}}>*</span></label>
                <TextField className="input-form" type="text" value={user.email} name="email" onChange={handleinput}
                 placeholder="example@gmail.com"
                 error={Boolean(errors?.email)}
                 helperText={errors?.email} />
                </div>
                </Grid>

                <Grid item  xs={12} sm={6} md={6}>
                <div className="form_title">
                <label className="label-form" htmlFor="about">Services <span style={{color:"red"}}>*</span></label>
                <TextField
                  id="country-select"
                  select
                  value={selectedCountry}
                  onChange={handleChange}
                  variant="outlined"
                  className="select-form"
                  defaultValue='Web Services'
                 
                  
                >
                  <MenuItem value="Web Services">Web Services</MenuItem>
                  <MenuItem value="UX Design Services">UX Design Services</MenuItem>
                  <MenuItem value="Mobile App Development">Mobile App Development</MenuItem>
                  <MenuItem value="Digital Marketing">Digital Marketing</MenuItem>
                
                </TextField>
                </div>
              </Grid>

              <Grid item  xs={12} sm={12} md={12}>
                <div className="form_title">
                <label className="label-form" htmlFor="message">Describe your requirement <span style={{color:"red"}}>*</span></label>

                <TextField className="textarea"  id="message" name="message" value={user.message} rows={4} multiline  onChange={handleinput}
                 placeholder="Describe your requirement" 
                 error={Boolean(errors?.message)}
                 helperText={errors?.message} 
                 style={{backgroundColor:'#ffffff'}}
                 />
                 
                </div>
              </Grid>

              </Grid>
             
              </Box>
              <div className="submit-button"><button className="primary_button" >Get Started</button></div>
              </form>
              </Grid>
            </Grid>


            <Stack spacing={2} sx={{ width: '100%' }}>
  
              <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
              <Alert  severity="success" sx={{ width: '100%' }}>
                     Thank you for your submitting. Will get contact soon shortly.
                    </Alert>
                </Snackbar>

              </Stack>  

              <Stack spacing={2} sx={{ width: '100%' }}>
  
                <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                <Alert  severity="error" sx={{ width: '100%' }} >
                    {apierror}
                      </Alert>
                  </Snackbar>

               </Stack>


            </Box>
             </Container>
             </section>
        </>
    )
}

export default Help;

/*    
          */





                
