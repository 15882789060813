import React, {useState} from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Container } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";

const Service=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

    return (
        <>
            
            <section className="section">
          <Container maxWidth='lg'>
    
        <h3 className="heading_title" style={{margin:'0px 0px 0px',textAlign:'center',fontSize:'34px'}}>
         Your <span style={{color:'var(--secondary)'}}>Digital</span> Solution Partner
        </h3>
        <p className="title" style={{textAlign:'center',margin:'12px 0px 44px'}}>We design products to help you expand your business, sell more and do more.</p>
         
         
        <Box>
           <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="space-between" alignItems='center' >

               
       <Grid item xs={12} sm={isSmscreen?12:6} md={4} display='flex' justifyContent='center' >
              
              <div className="backgroundsecondaryheight white">
                <div style={{paddingBottom:'10px',fontWeight:'800',fontSize:'24px'}}>We Build</div>
                <div className="home_font">We provide web services for your business
                    to truly succeed. We're also hereafter deployment in case
                    you need additional supports.

                    <ul className="ul footer_font" style={{color:"#fafafa"}}>
  <li>
    <NavLink to="/our-expertise/e-commerce-solution" className='home uihover' style={{color:"#fafafa"}}>
      Custom e-commerce Solutions
    </NavLink>
  </li>
  <li>
    <NavLink to="/our-expertise/mobile-app-development" className='home uihover' style={{color:"#fafafa"}}>
      Mobile App Development
    </NavLink>
  </li>
  <li>
    <NavLink to="" className='home uihover' style={{color:"#fafafa"}}>
      Custom Tool Development (CRM, Billing Software)
    </NavLink>
  </li>
  <li>
    <NavLink to="/our-expertise/web-services" className='home uihover' style={{color:"#fafafa"}}>
      Web Application Development (Food delivery app, Taxi booking, Service booking etc.)
    </NavLink>
  </li>
  <li>
    <NavLink to="/our-expertise/web-services" className='home uihover' style={{color:"#fafafa"}}>
      WordPress Development
    </NavLink>
  </li>
</ul>

                </div>

              </div>

        </Grid>
        <Grid item xs={12} sm={isSmscreen?12:6} md={4} display='flex' justifyContent='center' >
             
        <div className="backgroundheight">
                <div style={{paddingBottom:'10px',fontWeight:'800',fontSize:'24px'}}>We Design</div>
                <div className="home_font">We deliver excellent, User Experience Design (CoE).
                    Which helps our client to grow and scale up their business in a excellent 
                        way.
                    <ul className="ul footer_font">
                       <li>
                        <NavLink to='/our-expertise/ui-ux-design-services' style={{color:"#000000"}} className='home uxhover'>User Experience</NavLink> 
                        </li> 
                        <li>User Interface</li>
                        <li>Wire framing</li>
                        <li>Mobile App Design (iOS & Android)</li>
                        <li>Rapid Prototyping</li>
                        <li>Product Design</li>
                    </ul>
                </div>

              </div>

        </Grid>
        <Grid item xs={12} sm={isSmscreen?12:6} md={4} display='flex' justifyContent='center' >
             
        <div className="backgroundsecondaryheight white">
                <div style={{paddingBottom:'10px',fontWeight:'800',fontSize:'24px'}}>We Manage</div>
                <div className="home_font">We also provide project support and maintenance to 
                    our customers so that they can expedite up their delivery-
                    reliably and securely.

                    <ul className="ul footer_font" style={{color:"#fafafa"}}>
                        <li>Project Maintenances & Support</li>
                        <li>Project Deployment</li>
                        <li>Networking & Security</li>
                        <li>Cloud Computing (Azure & AWS)</li>
                        <li>DevOps</li>
                        <li>Azure e-mail Services</li>
                    </ul>
                </div>

              </div>

        </Grid>

   </Grid>
      
            </Box>

           </Container>
           </section>
        </>
    )
}
export default Service;
