import React, { useEffect, useState } from "react";
import { Box, Container, Grid,Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useLocation } from "react-router-dom";
import { createTheme, useMediaQuery } from "@mui/material";
import Contentskeleton from "./Contentskeleton";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Helmet } from 'react-helmet';

const Content=()=>
{

   const readmore={

      fontSize:'16px',
      fontWeight:'700',
      fontFamily:'var(--font-family)',
      color: '#151515',
      lineHeight:'normal',
      textTransform:'none',
      marginBlock:'0px',
      paddingBlock:'0px',
      width:'fit-content',
      '&:hover': {
         backgroundColor:'none !important',
      },
   }
   
    const navigate =useNavigate()

   const Readmore=(title, blogId)=>
   {
    
      localStorage.setItem('blogId',blogId)
     const encodedTitle = encodeURIComponent(title.replace(/\s+/g, '-').replace(/[/]/g, '-').replace(/\?/g, ''));
   
     window.scrollTo({ top: 0, behavior:'instant' });

     navigate(`/blogs/${encodedTitle.toLocaleLowerCase()}`);

   }

   const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })


 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const [isloading,setIsloading]=useState(true);

 const [loading, setloading]=useState(true);
 
 const [user, setUser]=useState()

 const [filterdata, setfilterdata] = useState()

 const Baseurl = process.env.REACT_APP_BASE_URL;

 const [showchips, setshowchips] = useState('Allpost')

 const category=localStorage.getItem('category')

 useEffect(()=>
 {
   
   if(category)
   {
      setshowchips(category)

    const apiurl = `${Baseurl}/blog/filter-data/${category}`

   fetch(apiurl)
   .then((response) => response.text())
   .then((result) => {

     const data= JSON.parse(result);

     if(data.status==='success')
      {
         setfilterdata(data.data)
          setIsloading(false)
          setloading(false)
      }
 })

}
 },[category])


 const options = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};


 useEffect(()=>
 {
    const apiurl = `${Baseurl}/blog`

    const fetchdata= async()=>
    {
       try {

      const response = await fetch(apiurl);
      const data = await response.json();
      if(data.status==='success')
      {
   
         setUser(data.data)
         setfilterdata(data.data)
         setIsloading(false)
         setloading(false)
      }
      
      }catch(error)
      {
        console.log(error)
      }
    }

     if(isloading && category===null)
     {
        fetchdata();
     }
 },[isloading])


const handlePost=(blog_category)=>
{

   localStorage.removeItem('category')
  
   if(blog_category!=='Allpost')
   {
      setshowchips(blog_category)

   const apiurl = `${Baseurl}/blog/filter-data/${blog_category}`

   fetch(apiurl)
   .then((response) => response.text())
   .then((result) => {

     const data= JSON.parse(result);

     if(data.status==='success')
      {
         setfilterdata(data.data)
      }
 })
}

 else 
  {
     
      setIsloading(true)
      setshowchips(blog_category)
      setfilterdata(user)
  }

}


const useFullCanonicalUrl = () => {
   const location = useLocation();
   const baseUrl = `${window.location.protocol}//${window.location.host}`;
   return `${baseUrl}${location.pathname}${location.search}`;
 };
 
 const canonicalUrl = useFullCanonicalUrl();



    return(
        <>

         <Helmet>
         <link rel="canonical" href={canonicalUrl} />
         </Helmet>


        <section>
       

        <div style={{backgroundColor:'#18143B',paddingBlock:'20px'}}>
        <Container maxWidth='lg'>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gridColumnGap:'20px'}}>

            <div className="hero_title wireless_gadget"><span style={{color:'#ffffff'}}>Top pick</span><br></br><span style={{color:'var(--primary)'}}>article for you</span></div>

            </div>
         </Container>
        </div>
           <div  style={{margin:'60px 0px'}}>

        <Container maxWidth='lg'>
          <div style={{margin:'20px 0px', display:'grid', gridRowGap:'10px'}}>
          <div style={{fontSize: isSmscreen?'20px' :'24px',fontFamily:'var(--fontfamily)',fontWeight:600}}>Trending Post</div>

           <div style={{display:'flex', flexWrap:'wrap', gridColumnGap:'20px'}}>

          <div className= {showchips==="Allpost"?"blog_category_chips":"blog_category_chips-not-selected"} onClick={()=>handlePost('Allpost')} >All post </div>
         <div className= {showchips==="Design"?"blog_category_chips":"blog_category_chips-not-selected"} onClick={()=>handlePost('Design')}>Design</div>
          <div className= {showchips==="Development"?"blog_category_chips":"blog_category_chips-not-selected"}  onClick={()=>handlePost('Development')}>Development </div>
          <div className= {showchips==="Marketing"?"blog_category_chips":"blog_category_chips-not-selected"}  onClick={()=>handlePost('Marketing')}>Marketing </div>
          <div className= {showchips==="Server"?"blog_category_chips":"blog_category_chips-not-selected"}  onClick={()=>handlePost('Server')}>Server</div>
          <div className= {showchips==="Technology"?"blog_category_chips":"blog_category_chips-not-selected"}  onClick={()=>handlePost('Technology')}>Technology</div>

          </div>
          </div>
            
            {loading ?
                <>
                 <Contentskeleton/>
                </> 
                
                :
      
    

        <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={4} columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start" style={{paddingTop:'10px'}} >
       
                {filterdata && filterdata?.filter(cur => cur.status === 'published')?.map((cur,index)=>
                {

                  const formattedDateTime = new Date(cur.created_on).toLocaleString('en-IN', options);

                  const cleanedDescription = cur.blog_description.replace(/<p><br\s?\/?><\/p>|<h[1-6]><br\s?\/?><\/h[1-6]>/g, '');

                    return(
                        <>
                        <Grid item xs={10} sm={5}  md={4}>
                            <div className="transportation">
                          
                                <div><LazyLoadImage src={cur.featured_image} alt="image" className="abstracts"  loading="lazy"/></div>
                                <div className="sar">
                                 <div className="footer_font">{formattedDateTime}</div>   
                                <div className="title">
                                {cur.blog_title.length > 50 ? `${cur.blog_title.substring(0, 50)}...` : cur.blog_title}
                                </div>
                                <div className="describes">
                                <span dangerouslySetInnerHTML={{ __html: cur.blog_description.length > 150 ? `${cleanedDescription.substring(0, 150)}...` : cleanedDescription }} />
                                </div>

                                <div style={{display:'flex', justifyContent:'flex-end',position:'absolute', right:'6px',bottom:'20px'}}>
                                <Button style={readmore} className="expand_all" onClick={()=>Readmore(cur.blog_title, cur._id)}><span className="expand">Read more</span> <KeyboardArrowRightOutlinedIcon/></Button>
                                </div>

                                </div>

                            </div>
                            </Grid>
                        </>
                    )
                })}
               
             </Grid>
             </Box>
               }

            {filterdata && filterdata.length===0 && 
                           <div style={{paddingTop:'40px'}} >
                              <div className="section_title">No data available</div>
                              
                              </div>}
         
             </Container>
             </div>
             </section>
        </>
    )
}
export default Content;

/* 

              <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end',gridRowGap:'6px'}}>
                <div style={{fontSize: isSmscreen? '16px':'20px',fontFamily:'var(--fontfamily)', color:'#B1B1B1',fontWeight:500, cursor:'pointer'}} onClick={()=>handlePost('Allpost')}>All Post</div>
                <div style={{fontSize: isSmscreen?'20px' :'24px',fontFamily:'var(--fontfamily)', color:'#ffffff',fontWeight:500, cursor:'pointer'}} onClick={()=>handlePost('Design')}>Design</div>
                <div style={{fontSize:isSmscreen?'20px' :'24px',fontFamily:'var(--fontfamily)', color:'#ffffff',fontWeight:500,  cursor:'pointer'}}  onClick={()=>handlePost('Development')}>Development</div>
                <div style={{fontSize: isSmscreen?'20px' :'24px',fontFamily:'var(--fontfamily)', color:'#ffffff',fontWeight:500, cursor:'pointer'}} onClick={()=>handlePost('Marketing')}>Marketing</div>
              </div>


<div>
             <NavLink to="/addblog"><button className="page_title gadget primary_button">Add New Blog</button></NavLink>
             </div>
             */