import React, {useState} from "react";
import Grid from '@mui/material/Grid';
import { Box, createTheme, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import { Link, NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const Header=()=>
{

    const themes=createTheme({
       breakpoints:{
        values:
        {
           xs:0,
           sm:768
        }
       }
    })
 
    const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

    const [anchorElNav, setAnchorElNav] = useState(null);

   const navigate= useNavigate()
    
    const settings = [
      { text: 'Home' },
      { text: 'Our Services' },
      { text: 'Blogs' },
      { text: 'About Us' },
      { text: 'Contact Us' }
    ];


    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const Hide=(values)=>
    {
       
       switch(values)
       {
          case 0 : {

            window.scrollTo({ top: 0, behavior:'instant' });
                navigate('/')
         }
          break;
   
          case 1: {

           window.scrollTo({ top: 0, behavior:'instant' });
            navigate('/our-expertise')
          }
           break;
   
           case 2: {
            window.scrollTo({ top: 0, behavior:'instant' });
            navigate('/blogs')
         }
          break;
        
          case 3: {
         
            window.scrollTo({ top: 0, behavior:'instant' });
           navigate('/about-us')
        
          }
          break;

          case 4: {
         
            window.scrollTo({ top: 0, behavior:'instant' });
            navigate('/contact-us')
         
           }
           break;
       }
   
    }

    const home=()=>
    {
      
      window.scrollTo({ top: 0, behavior:'instant' });
      window.location.replace('/');
    }

    const [state, setState] = useState({
      right: false,
    });

    const [statemobile, setStatemobile] = useState({
      right: false,
    });

    
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

    const toggleDrawermobile = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setStatemobile({ ...statemobile, [anchor]: open });
    };

    const list = (anchor) => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
    <ListItem>
      <div className="footer_primary_title">Our Services</div>
    </ListItem>
    {[
      { text: 'Web Services', path: '/our-expertise/web-services' },
      { text: 'UI/UX Design Services', path: '/our-expertise/ui-ux-design-services' },
      { text: 'Mobile App Development', path: '/our-expertise/mobile-app-development' },
      { text: 'Digital Marketing', path: '/our-expertise/digital-marketing' },              
      { text: 'Wordpress Website Development', path: '/our-expertise/wordpress-website-develomemt' }
    ].map((item, index) => (
      <ListItemButton key={index} disablePadding   component={Link} to={item.path}
      sx={{
        '&:hover': {
          backgroundColor: '#FFF2A0',
        },
      }}
      >
        <ListItemText
          primary={item.text}
          primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
        />
      </ListItemButton>
    ))}
  </List>
        <Divider />
        <List>
    <ListItem>
      <div className="footer_primary_title">Resources</div>
    </ListItem>
    {[
      { text: 'Whitepaper', path: '/white-paper' },
      { text: 'Case Studies', path: '/case-study' },
    ].map((item, index) => (
      <ListItemButton key={index} disablePadding   component={Link} to={item.path}
      sx={{
        '&:hover': {
          backgroundColor: '#FFF2A0',
        },
      }}>
        <ListItemText
          primary={item.text}
          primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
        />
      </ListItemButton>
    ))}
  </List>
      </Box>
    );


    const listmobile = (anchor) => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawermobile(anchor, false)}
        onKeyDown={toggleDrawermobile(anchor, false)}
      >

          <List>
              <ListItem>
                <div className="footer_primary_title">Home</div>
              </ListItem>
              {[
                { text: 'Home', path: '/' },
              ].map((item, index) => (
                <ListItemButton key={index} disablePadding   component={Link} to={item.path}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFF2A0',
                  },
                }}>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
                  />
                </ListItemButton>
              ))}
            </List>
            <Divider />

            <List>
            <ListItem>
              <div className="footer_primary_title">Services</div>
            </ListItem>
            {[
              { text: 'Web Services', path: '/our-expertise/web-services' },
              { text: 'UI/UX Design Services', path: '/our-expertise/ui-ux-design-services' },
              { text: 'Mobile App Development', path: '/our-expertise/mobile-app-development' },
              { text: 'Digital Marketing', path: '/our-expertise/digital-marketing' },
              { text: 'Wordpress Website Development', path: '/our-expertise/wordpress-website-develomemt' }
            ].map((item, index) => (
              <ListItemButton key={index} disablePadding   component={Link} to={item.path}
              sx={{
                '&:hover': {
                  backgroundColor: '#FFF2A0',
                },
              }}>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
                />
              </ListItemButton>
            ))}
          </List>
        <Divider />

            <List>
        <ListItem>
          <div className="footer_primary_title">Resources</div>
        </ListItem>
        {[
          { text: 'Whitepaper', path: '/white-paper' },
          { text: 'Case Studies', path: '/case-study' },
        ].map((item, index) => (
          <ListItemButton key={index} disablePadding   component={Link} to={item.path}
          sx={{
            '&:hover': {
              backgroundColor: '#FFF2A0',
            },
          }}>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
            />
          </ListItemButton>
        ))}
      </List>

        
      <List>
              <ListItem>
                <div className="footer_primary_title">Blogs</div>
              </ListItem>
              {[
                { text: 'Blogs', path: '/blogs' },
              ].map((item, index) => (
                <ListItemButton key={index} disablePadding   component={Link} to={item.path}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFF2A0',
                  },
                }}>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
                  />
                </ListItemButton>
              ))}
            </List>
        <Divider />
        
        <List>
              <ListItem>
                <div className="footer_primary_title">About Us</div>
              </ListItem>
              {[
                { text: 'About Us', path: '/about-us' },
              ].map((item, index) => (
                <ListItemButton key={index} disablePadding   component={Link} to={item.path}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFF2A0',
                  },
                }}>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
                  />
                </ListItemButton>
              ))}
            </List>
        <Divider />
        <List>
              <ListItem>
                <div className="footer_primary_title">Contact Us</div>
              </ListItem>
              {[
                { text: 'Contact Us', path: '/contact-us' },
              ].map((item, index) => (
                <ListItemButton key={index} disablePadding   component={Link} to={item.path}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFF2A0',
                  },
                }}
                >
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ style: { fontFamily: 'var(--font-family)', fontSize: '14px' } }}
                  />
                </ListItemButton>
              ))}
            </List>
        
      </Box>

      
    );


    return (
        <>
          <div className="header">
          <Container maxWidth="lg">
           <Box sx={{width:"100%"}}>
           <Grid container  display="flex" justifyContent="space-between" alignItems="center" >
               <Grid item xs={5} sm={5} md={5}>
                    <div><LazyLoadImage className="logo-img" src='/wethink.svg' alt="WeThink"  loading="lazy" onClick={home}/></div>
                    </Grid>
                    <Grid item xs='none' sm={isSmscreen?{}:7} md={7} display="flex" justifyContent="flex-end">
                  <div className="navlink">
                       <NavLink to ="/" className="home white"> <div className="homehover">Home</div> </NavLink> 
                       <NavLink to="/our-expertise" className="home white"> <div className="homehover" >Our Services</div></NavLink> 
                       <NavLink to="/blogs" className="home white">  <div className="homehover" >Blogs</div></NavLink> 
                       <NavLink to="/about-us" className="home white"><div className="homehover" >About Us</div></NavLink> 
                       <NavLink to="/contact-us" className="home white"><div className="homehover">Contact Us</div></NavLink> 

                                <div>
                  {['right'].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <IconButton
                        size="large"
                        style={{ color: 'white' }}
                        aria-label="menu"
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>

        
                 </div>
                 </Grid>

            <Grid item xs={7} sm={isSmscreen?7:'none'} md='none' display={isSmscreen ? 'flex' : 'none'} justifyContent="flex-end">
           
            <div>
                  {['right'].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <IconButton
                        size="large"
                        style={{ color: 'white' }}
                        aria-label="menu"
                        onClick={toggleDrawermobile(anchor, true)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor={anchor}
                        open={statemobile[anchor]}
                        onClose={toggleDrawermobile(anchor, false)}
                      >
                        {listmobile(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>


          </Grid>

                </Grid>
                </Box>  

             
   
    
       </Container>
          </div>
        

   
        </>
    )
}

export default Header;

/*  
    {['left'].map((anchor) => (
        <div key={anchor}>
         <div className="hamburger"> <button className="mobile_link" onClick={toggleDrawer(anchor, true)}><MenuIcon className="menu-icon" onClick={menuLink}/></button></div>
        {close &&  <Drawer
            anchor={anchor}
            open={state[anchor]}
            className="drawer"
          >
           <div className="closeicon" onClick={Close}><CloseIcon className="close-icon" style={{fontSize:"37px", boxSizing:"border-box", padding:"5px"}} /></div>
            {list(anchor)}
          </Drawer>}
        </div>
  
      ))}

 const [showMediaIcons,setShowMediaIcons] = useState(true);

  const [state, setState] = useState({
    left: false,
  });


 const [close, setClose]=useState(true);



  const show=()=>
  {
    setShowMediaIcons(!showMediaIcons)
  }
const Close=()=>
{
  setClose(false);
}


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const menuLink=()=>
  {
    setClose(true);
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100vw' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>

          <ListItem  disablePadding >
            <ListItemButton>
            <div className="nav_bar">
                      <NavLink to ="/" className="home"> <div className="list_item"  style={{cursor:"pointer"}}>Home</div> </NavLink> 
                       <NavLink to="/services" className="home"> <div className="list_item"   style={{cursor:"pointer"}}>Services</div></NavLink>
                       <NavLink to="/blogs" className="home">  <div className="list_item"  style={{cursor:"pointer"}}>Blogs</div></NavLink>  
                       <NavLink to="/about-us" className="home"><div className="list_item" style={{cursor:"pointer"}}>About Us</div></NavLink> 
                       <NavLink to="/contact-us" className="home"><div className="list_item"   style={{cursor:"pointer"}}>Contact Us</div></NavLink> 
                       </div>
            </ListItemButton>
          </ListItem>
      
      </List>
    </Box>
  );
      <button className="mobile_link" onClick={show}>
                  <MenuIcon />
                  </button>

















          */