import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';




const Appdevelopment=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: isSmscreen?'20px 14px':'60px 20px',
        marginTop: '20px',
        borderRadius: '8px',
        cursor: 'pointer',
        boxShadow:'none'
      }));

    return(
        <> 

          <section className="section">
          <Container maxWidth='lg' >

          <Box>
          <div>
          <h3 className ="heading_title" style={{textAlign:'center',marginBlock:'0px'}}>Agile Mobile Development App</h3>
        </div>
          
          <Box>
            <Grid container display="flex" justifyContent="center" textAlign="center">
                <Grid item xs={12} sm={8} md={8} >
                <p className="title"  style={isSmscreen? {fontWeight:'400'}:{padding:'4px 0px 36px',fontWeight:'400',lineHeight:'26px'}}>
                Working with an experienced mobile applications developer, you get predictably successful results. 
                Using a proactive approach and agile process, 
                we make sure your time and investment are optimized at every step of development and you get
                 a digital product that fully meets your requirements and even exceeds your expectations.
              </p>

                </Grid>
            </Grid>
       
          </Box>

          <Box>
            <Grid container rowSpacing={2} columnSpacing={{sm:2,md:4}} display="flex" justifyContent="space-between">
                
                <Grid item xs={12} sm={isSmscreen?6:3} md={3} >
                 <Item style={{backgroundColor:'#FFFDF3',textAlign:'center'}} className="brand">
                    <div>
                 <LazyLoadImage
                className='msgimage'
                src="/msg.png"
                alt="Consulting & Strategy"
                loading="lazy"
              
            />     
            </div>
                <div className="title" style={{fontWeight:'700'}}>Consulting & Strategy</div>

                 </Item>
                </Grid>
                <Grid item xs={12} sm={isSmscreen?6:3} md={3} >
                 <Item style={{backgroundColor:'#FFFDF3',textAlign:'center'}} className="brand">
               
                 <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='ellipsesimages'
                src="/ellipses.png"
                alt="ellipses"
                loading="lazy"
               
            />  
                 <LazyLoadImage
                className='faceimages'
                src="/face.png"
                alt="Design & UX"
                loading="lazy"
             
            />     
            </div>
                <div className="title" style={{fontWeight:'700'}}>Design & UX</div>

                 </Item>
                </Grid>
                <Grid item xs={12} sm={isSmscreen?6:3} md={3} >
                 <Item style={{backgroundColor:'#FFFDF3',textAlign:'center'}} className="brand">
                 
                 <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='ellipsesimages'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
          
            />  
                 <LazyLoadImage
                className='faceimages'
                src="/closing.png"
                alt="Engineering & Delivery"
                loading="lazy"
             
            />     
            </div>    
                <div className="title" style={{fontWeight:'700'}}>Engineering & Delivery</div>

                 </Item>
                </Grid>
                <Grid item xs={12} sm={isSmscreen?6:3} md={3} >
                 <Item style={{backgroundColor:'#FFFDF3',textAlign:'center'}} className="brand">
                
                 <div style={{position:'relative'}}>
                 <LazyLoadImage
                className='ellipsesimages'
                src="/ellipses.png"
                alt="msg"
                loading="lazy"
              
            />  
                 <LazyLoadImage
                className='faceimages'
                src="/computer.png"
                alt="QA & Optimize"
                loading="lazy"
              
            />     
            </div> 
                <div className="title" style={{fontWeight:'700'}}>QA & Optimize</div>

                 </Item>
                </Grid>
            </Grid>
       
          </Box>


          </Box>

           </Container>
           </section>
        </>
    )
}

export default Appdevelopment;