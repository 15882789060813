import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";


const Lifecycle=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))



     const [isImageLoaded, setImageLoaded] = useState(false);

     useEffect(() => {
         const img = new Image();
         img.src = '/lifecycle.png';
         img.onload = () => {
             setImageLoaded(true);
         };
     }, []);

     const [showContent, setShowContent] = useState(false);

     useEffect(() => {
         setShowContent(true);
     }, []);

    return(
        <> 

          <section className="section">
          <Container maxWidth='lg' >

          <Box>
          {showContent && (
          <div>
          <h3 className ="heading_title" style={{textAlign:'center',padding:isSmscreen?'20px':'20px 0px 80px',margin:'0px'}}>Our App Development Lifecycle Process</h3>
        </div>
          )}
  
          <Box>
            <Grid container>

                <Grid item xs={12} sm={8} md={8} >

                {showContent && (
                <LazyLoadImage
                src="/lifecycle.png"
                alt="Our App Development LifeCycle Process"
                loading="lazy"
                effect="blur"
                style={{
                    maxWidth: '94vw',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: isImageLoaded ? 1 : 0, 
                    transition: 'opacity 0.3s ease-in-out',
                }}

            />  
                )}
                </Grid>
            </Grid>
       
          </Box>
          </Box>

           </Container>
           </section>
        </>
    )
}

export default Lifecycle;