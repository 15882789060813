import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, createTheme, useMediaQuery,Grid } from "@mui/material";
import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const Experience=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: isSmscreen?'40px 18px':'60px 20px',
        marginTop: '20px',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out', 
        '&:hover': {
          transform: 'scale(1.03)',
        },
      }));
    
   


    return (
        <>
               <section className="section">
            <Container maxWidth='lg'>

            <Box> 
          
            <Grid container rowSpacing={{xs:2,sm:3}} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent='center' textAlign='center'>
                <Grid item xs={12} sm={isSmscreen?12:10} md={10}>
                   

            <h3 className="page_title" style={{marginBottom:'0px'}}> Creative User Experience  <span className='bold'>Design Services</span> </h3>  

            <p className='line-height title'style={{margin:'12px 0px 6px'}} >We offer a wide range of UI/UX design services for your web and mobile apps to create a strong business identity.</p>

            <div style={{display:"flex",justifyContent:'center', padding:'20px 0px 12px'}}>
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="UX-Design"
                    loading="lazy"
                 
                />
                   </div>


                </Grid>

                <Grid item xs={12} sm={isSmscreen?8:4} md={4}>
                 
                  <Item  className='box-shadow'>
              <div>
                  <LazyLoadImage
                    style={{maxWidth:'100%'}}
                    src="/UX Strate-and Consulting.svg"
                    alt="UX Strate-and Consulting"
                    loading="lazy"
                   
                />
                </div>

            <h1 className="primary_title bold" style={{margin: isSmscreen?'12px 0px':'14px 0px'}}>UX Strate and Consulting</h1>

                  </Item>


                </Grid>

                <Grid item xs={12} sm={isSmscreen?8:4} md={4}>
                 
                 <Item  className='box-shadow'>
                <div >
                 <LazyLoadImage
                    style={{maxWidth:'100%'}}
                   src="/Website Design.svg"
                   alt="Website Design"
                   loading="lazy"
                 
               />
               </div>

           <h1 className="primary_title bold" style={{margin: isSmscreen?'12px 0px':'14px 0px'}}>Website Design</h1>

                 
                

                 </Item>


               </Grid>

               <Grid item xs={12} sm={isSmscreen?8:4} md={4}>
                 
                 <Item  className='box-shadow'>
                     
                     <div>
                 <LazyLoadImage
                  style={{maxWidth:'100%'}}
                   src="/Mobile App Design.svg"
                   alt="Mobile App Design"
                   loading="lazy"
                 
               />
               </div>

           <h1 className="primary_title bold" style={{margin: isSmscreen?'12px 0px':'14px 0px'}}>Mobile App Design</h1>

                

                 </Item>


               </Grid>


               <Grid item xs={12} sm={isSmscreen?8:4} md={4}>
                 
                 <Item  className='box-shadow'>
             <div>
                 <LazyLoadImage
                  style={{maxWidth:'100%'}}
                   src="/UserTesting.svg"
                   alt="User Testing"
                   loading="lazy"
                 
               />
               </div>

           <h1 className="primary_title bold" style={{margin: isSmscreen?'12px 0px':'16px 0px'}}>User Testing</h1>

                 </Item>


               </Grid>

               <Grid item xs={12} sm={isSmscreen?8:4} md={4}>
                
                <Item  className='box-shadow'>
               <div >
                <LazyLoadImage
                   style={{maxWidth:'100%'}}
                  src="/Wireframes & Blueprints.svg"
                  alt="Wireframes & Blueprints"
                  loading="lazy"
                 
              />
              </div>

          <h1 className="primary_title bold" style={{margin: isSmscreen?'12px 0px':'14px 0px'}}>Wireframes & Blueprints</h1>

                
               

                </Item>


              </Grid>

              <Grid item xs={12} sm={isSmscreen?8:4} md={4}>
                
                <Item  className='box-shadow'>
                    
                    <div>
                <LazyLoadImage
                 style={{maxWidth:'100%'}}
                  src="/Cross Platform Compatibility.svg"
                  alt="Cross Platform Compatibility"
                  loading="lazy"
                
              />
              </div>

          <h1 className="primary_title bold" style={{margin: isSmscreen?'12px 0px':'16px 0px'}}>Cross Platform Compatibility</h1>

               

                </Item>


              </Grid>

              


            </Grid>
        
            
                
            </Box>  
           

            </Container>
            </section>
        </>
    )
}

export default Experience;
