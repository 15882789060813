import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Box, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";  
import { motion } from "framer-motion";  


const Home=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
     const isLargeScreen = useMediaQuery('(min-width:1200px)')

     const navigate=useNavigate()

     const containerContent = {
      visible: { x: 0, opacity: 1, transition: { duration: 1 } },
      hidden: { x: -100, opacity: 0 }
    };
  
  

     
    const customButton=
    {
  
    backgroundColor:'var(--primary)',
    color:'#000000',
  fontSize:'18px',
  padding:'12px 24px',
  fontWeight: 600,
  textTransform:'none',
  margin:isSmscreen?'10px 0px 20px':'20px 0px 30px',
  letterSpacing: '0.8px',  
    '&:hover': {
   backgroundColor:'var(--primary)',
    },
    }

    const started=()=>
    {
      window.scrollTo({ top: 0, behavior:'instant' });
       navigate('/contact-us')
    }

    
   

    return(
        <>

     
          <section style={{background:'#18143B'}} >
         {isLargeScreen? <div className="primarycontainer">
     
          <Box>
           <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:isSmscreen?4:6,md:6}} display="flex" justifyContent="space-between" alignItems='center' >
         
        
       <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent='center'>

       <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerContent}
              viewport={{ once: false}}
              style={{overflowX:'hidden'}}
          >

        <h1 className="hero_title white" style={isSmscreen? {paddingTop:'20px'}:{}}>
          We craft excellent <span style={{color:'var(--primary)'}}>Digital</span> products
        </h1>
        <h2 className="secondary_title white">
          WeThink is helping startups and SMEs on their <span style={{color:'var(--primary)'}}>digital transformation</span> journey with 
          innovation and CoE technology.
        </h2>

        <Button style={customButton} onClick={started} className="primary_button">Let's Get Started</Button>

        </motion.div>
 
        </Grid>
  
       

            <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'flex-end'} style={isSmscreen?{paddingBottom:'30px'}:{}} >
           
          
          <div   className="image_container" >
            <LazyLoadImage
                className='aboutimage'
                src= {isSmscreen ? "/crafting-digital-products.jpeg" : "/crafting-digital-product.jpeg"}
                alt="Crafting Digital Products Design"
                loading="lazy"
                style={{
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,

                }}
               
            />
        </div>
       
     
       </Grid>
       
   </Grid>
      
            </Box>

            </div>
            :
            <Container maxWidth='lg' style={isSmscreen ?{paddingRight:'24px'}:{paddingRight:'0px'}}>
     
            <Box>
             <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:isSmscreen?4:6,md:6}} display="flex" justifyContent="space-between" alignItems='center' >
           
          
         <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent='center'>
       
   
         <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerContent}
              viewport={{ once: false}}
              style={{overflowX:'hidden'}}
          >
  
          <h1 className="hero_title white" style={isSmscreen? {paddingTop:'20px'}:{}}>

           We craft excellent <span style={{color:'var(--primary)'}}>Digital</span> products
          </h1>
          
          <h2 className="secondary_title white">
            WeThink is helping startups and SMEs on their <span style={{color:'var(--primary)'}}>digital transformation</span> journey with 
            innovation and CoE technology.
          </h2>
          
          
        <Button style={customButton} onClick={started} className="primary_button">Let's Get Started</Button>

          </motion.div>
     
          
          </Grid>
    
         
  
              <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'flex-end'} style={isSmscreen?{paddingBottom:'30px'}:{}} >
           
              <LazyLoadImage
                  className='aboutimage'
                  src= {isSmscreen ? "/crafting-digital-products.jpeg" : "/crafting-digital-product.jpeg"}
                  alt="Crafting Digital Products Design"
                  loading="lazy"
                  style={{
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                  }}
                 
              />
           
       
         </Grid>
         
     </Grid>
        
              </Box>
  
              </Container>
}
           </section>
        </>
    )
}

export default Home;

/*          <LazyLoadImage style={isSmscreen ?{paddingTop:'20px',width:'220px'}:{paddingTop:'20px'}} src="/wethink.png" alt="wethink"/> 
*/