import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, createTheme, useMediaQuery,Grid } from "@mui/material";
import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const Uxservices=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: isSmscreen?'30px 18px':'40px 20px',
        marginTop: '20px',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out', 
        '&:hover': {
          transform: 'scale(1.03)',
        },
      }));
    
   


    return (
        <>
               <section className="section" style={{backgroundColor:'#FFFCEC'}}>
            <Container maxWidth='lg'>

            <Box> 
          
            <Grid container rowSpacing={{xs:2,sm:3}} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent='center' textAlign='center'>
                <Grid item xs={12} sm={isSmscreen?12:10} md={10}>
                   

            <h1 className="page_title" style={{marginBottom:'0px'}}> Why Choose We Think for <span className='bold'>UI/UX</span> Services? </h1>  

            <p className='line-height title'style={{margin:'12px 0px 6px'}} >Our UI/UX design services are web & mobile-oriented, bringing the best experience to
               users and a compelling brand presence.</p>

            <div style={{display:"flex",justifyContent:'center', padding:'20px 0px 12px'}}>
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="UX-Design"
                    loading="lazy"
                  
                />
                   </div>


                </Grid>

                <Grid item xs={12} sm={isSmscreen?6:3} md={3}>
                 
                  <Item className='ux-services box-shadow'>
              <div  className='background-ui_ux'>
                  <LazyLoadImage
                   style={{maxWidth:isSmscreen?'100%':'120px'}}
                    src="/ui-ux-service-1.svg"
                    alt="UX-Design"
                    loading="lazy"
            
                />
                </div>

            <h1 className="primary_title bold" style={{margin:'0px'}}>350K+ Man Hours of Work</h1>

                    <p className="home_font" style={{margin:'0px',fontWeight:'400'}}>
                    Depth of experience to deliver result.
                    </p>
                 

                  </Item>


                </Grid>

                <Grid item xs={12} sm={isSmscreen?6:3} md={3}>
                 
                 <Item className='ux-services box-shadow'>
                <div  className='background-ui_ux'>
                 <LazyLoadImage
                  style={{maxWidth:isSmscreen?'100%':'120px'}}
                   src="/ui-ux-service-2.svg"
                   alt="UX-Design"
                   loading="lazy"
                  
               />
               </div>

           <h1 className="primary_title bold" style={{margin:'0px'}}>20+ Industries <br></br> Served</h1>

                   <p className="home_font" style={{margin:'0px',fontWeight:'400'}}>
                   Domain knowledge to transform the industry.
                   </p>
                

                 </Item>


               </Grid>

               <Grid item xs={12} sm={isSmscreen?6:3} md={3}>
                 
                 <Item className='ux-services box-shadow'>
                     
                     <div  className='background-ui_ux'>
                 <LazyLoadImage
                  style={{maxWidth:isSmscreen?'100%':'120px'}}
                   src="/ui-ux-service-3.svg"
                   alt="UX-Design"
                   loading="lazy"
             
               />
               </div>

           <h3 className="primary_title bold" style={{margin:'0px'}}>4K+ Web <br></br>Projects</h3>

                   <p className="home_font" style={{margin:'0px',fontWeight:'400'}}>
                   Proven track record of success.
                   </p>
                

                 </Item>


               </Grid>

               <Grid item xs={12} sm={isSmscreen?6:3} md={3}>
                 
                 <Item className='ux-services box-shadow '>
                  <div className='background-ui_ux'>
                 <LazyLoadImage
                   style={{maxWidth:isSmscreen?'100%':'120px'}}
                   src="/ui-ux-service-4.svg"
                   alt="UX-Design"
                   loading="lazy"
                 
               />
               </div>

           <h1 className="primary_title bold" style={{margin:'0px'}}>90% on Time <br></br> Delivery</h1>

                   <p className="home_font" style={{margin:'0px',fontWeight:'400'}}>
                   Creativity delivered with perfection.
                   </p>
                

                 </Item>


               </Grid>


            </Grid>
        
            
                
            </Box>  
           

            </Container>
            </section>
        </>
    )
}

export default Uxservices;
