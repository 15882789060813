import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Allservices from "./Allservices";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';


const Services=()=>
{
  
    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();


    return (
        <>
           
           <Helmet>

        <link rel="canonical" href={canonicalUrl} />
        </Helmet>

             <div className="content">
              <Header/>
             <Allservices/>
           
            </div>
            <Footer/>

        </>
    )
}

export default Services;

/*
         <Mobileapp/>  
            <Digitalmarketing/>  

<div className="containers">
            <Header/>
            <div className="content">
             <Mobileapp/>
            <Footer/>
            </div>
           
            </div>
            */