import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check'; 
import { motion } from "framer-motion";      
import { NavLink } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';


const Product=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const [open, setOpen]=useState(false)

     const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

     const containerContent = {
      visible: { x: 0, opacity: 1, transition: { duration: 1 } },
      hidden: { x: -200, opacity: 0 }
    };
  
    const containerImage = {
      visible: {
        opacity: 1,
        x: 0,
        transition: { duration: 1, staggerChildren: 0.1, delayChildren: 0.1 },
      },
      hidden: { opacity: 0, x: 200 },
      
    };


    return(
        <>
             <section className="section">
          <Container maxWidth='lg'>
            
          <h3 className="heading_title" style={{paddingBottom:'12px',margin:'0px'}}>SaaS Product Development</h3>
          <p className='title' style={{fontWeight:500,margin:isSmscreen?'0px 0px 20px':'0px 0px 40px'}}>We specialize in SaaS (Software as a Service) product development, crafting cutting-edge software 
          solutions tailored to your business needs.</p>   

          <Box>
          <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="space-between" alignItems='center'>
          
          <Grid item xs={12} sm={isSmscreen?12:7} md={7} display='flex' justifyContent='center' >
         
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={containerContent}
            viewport={{ once: false }}
           
          >
        
               <div className="image_containers">
                <NavLink to='https://surveyadmin.azurewebsites.net/login/' target="_blank" aria-label="survey master">
              <LazyLoadImage
                    
                    src="/saas-product-development.png"
                    alt="SaaS Product Development"
                    loading="lazy"
                    
                />
                </NavLink>
             </div>

             </motion.div>
        

            </Grid>

                <Grid item xs={12} sm={isSmscreen?12:5} md={5} display='flex' justifyContent={isSmscreen?'center':'flex-end'}>

                <motion.div
                initial="hidden"
                whileInView="visible"
                variants={containerImage}
                viewport={{ once: false }}
                
          >

                <div style={{backgroundColor:'#FFFCEC',padding:'20px'}}>

                <h4 className="section_title"style={{margin:"0px"}} >Survey Master</h4>

                <p className="footer_font" style={{fontWeight:'600',color:'#000000',padding:'10px 0px 30px', margin:"0px"}}  >Craft online surveys swiftly, converting feedback into business growth. It driven by AI, provides rapid insights, helping your business thrive.</p>

                <ul className="ul footer_font" style={{paddingBottom:'10px',listStyleType:'none',paddingLeft:'0'}} >
                  <li><CheckIcon style={{transform:'scale(0.8)', color:'green'}}/> Create survey</li>
                  <li><CheckIcon style={{transform:'scale(0.8)', color:'green'}}/> Track real time survey's responses</li>
                  <li><CheckIcon style={{transform:'scale(0.8)', color:'green'}}/> Collect information and feedback</li>
                  <li><CheckIcon style={{transform:'scale(0.8)', color:'green'}}/> Download feedback data</li>
                  <li><CheckIcon style={{transform:'scale(0.8)', color:'green'}}/> Customize survey form theme/design</li>
                  <li><CheckIcon style={{transform:'scale(0.8)', color:'green'}}/> Powerful Web Survey portal for participant.</li>
                </ul>

                <div className="footer_primary_title">Project Duration: <span style={{fontWeight:500}}>90 days</span></div>
                <div className="footer_primary_title">Platform: <span style={{fontWeight:500}}>Web, iOS app and Android app</span></div>
                 <div style={{paddingTop:isSmscreen?'18px':'24px'}} className="footer_primary_title">
                <NavLink className="home uxhover" style={{color:'var(--secondary)'}} onClick={handleClickOpen}>Demo: Admin (portal)</NavLink>
                </div>
                </div>

                </motion.div>
            </Grid>

            </Grid>

            <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogTitle id="alert-dialog-title">
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',fontWeight:'600',fontFamily:'var(--font-family)',gridColumnGap:'40px'}}>
        Survey Master - Demo (Product Walkthrough)
        <CloseIcon style={{cursor:'pointer'}} onClick={handleClose}/>
  </span> 
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
          
          <table>
                      <td className="footer_font label_data" style={{lineHeight:'22px',width:isSmscreen?"100px":'120px'}}>Username: </td>
                      <td className="footer_font label_data" style={{lineHeight:'22px'}}><NavLink  to="mailto:robin@wethink.co.in" className='home uxhover' style={{wordBreak:'break-all'}}>robin@wethink.co.in</NavLink></td>
                   </table>

          </DialogContentText>
          <DialogContentText id="alert-dialog-description">

          <table>
                      <td className="footer_font label_data" style={{lineHeight:'22px',width:isSmscreen?"100px":'120px'}}>Password: </td>
                      <td className="footer_font label_data" style={{lineHeight:'22px'}}>Welcome@123</td>
                   </table>
        
          </DialogContentText>

          <DialogContentText id="alert-dialog-description">
         
          <table>
                      <td className="footer_font label_data" style={{lineHeight:'22px',width:isSmscreen?"100px":'120px'}}>Demo URL:</td>
                      <td className="footer_font label_data" style={{lineHeight:'22px'}}><NavLink to='https://surveyadmin.azurewebsites.net/login/' target="_blank" className="home uxhover" style={{wordBreak:'break-all'}}>https://surveyadmin.azurewebsites.net/login/</NavLink></td>
                   </table>
          
          </DialogContentText>

        </DialogContent>
        
      </Dialog>

          </Box>
          </Container>
          </section>
       
        </>
    )
}

export default Product;