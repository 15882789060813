import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const Ownwebsite=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const responsive = {
        
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 768, min: 0 },
          items: 2
        }
      };

      const slides = [
        {
          title:"Education" ,
          label:"Education websites",
          imageSrc: "/Educations.png",
          alt: "Education"
        },
        {
            title:"Restaurant" ,
            label:"Restaurant websites",
            imageSrc: "/Restaurant.png",
            alt: "Restaurant"
          },
          {
            title:"E-commerce" ,
            label:"E-commerce websites",
            imageSrc: "/E-commerce.png",
            alt: "E-commerce"
          },
          {
            title:"Consultancy" ,
            label:"Consultancy websites",
            imageSrc: "/Consultancy.png",
            alt: "Consultancy"
          },
          {
              title:"Pharma" ,
              label:"Pharma websites",
              imageSrc: "/Pharma.png",
              alt: "Pharma"
            },
            {
              title:"Travel" ,
              label:"Travel websites",
              imageSrc: "/Travels.png",
              alt: "Travel"
            },
            {
                title:"Real estate" ,
                label:"Real estate websites",
                imageSrc: "/Real estate.png",
                alt: "Real estate"
              },
      ];
     

    return(
        <>

          <section className="section">
          <Container maxWidth='lg'>
          <Box sx={{width:'100%'}}>
            <h3 className="page_title" style={{textAlign:'center',margin:'0px 0px 12px'}}>Types Of Websites We Develop</h3>



            <div style={{display:"flex",justifyContent:'center', padding:isSmscreen?'16px 0px 32px':'20px 0px 42px'}}>
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="UX-Design"
                    loading="lazy"
               
                />
                   </div>

                   <Carousel
                        responsive={responsive}
                       className="developcarousel"
                        >
                        {slides.map((slide, index) => (
                            <div key={index} className="box-shadow p-20">
                           
                              <div className="title" style={{fontWeight:'600'}}>{slide.title}</div>

                             <div className="home_fonts" style={{padding:isSmscreen?"8px 0px 0px":'10px 0px 0px'}}>{slide.label}</div>
                                   
                                <LazyLoadImage
                                style={{maxWidth:'100%'}}
                                src={slide.imageSrc}
                                alt={slide.alt}
                                loading="lazy"
                                />
                           
                            </div>
                        ))}
                        </Carousel>

      
            </Box>

           </Container>
           </section>
        </>
    )
}

export default Ownwebsite;