import React, {useState,useEffect} from 'react';
import { Avatar, Box, Button, Container, Grid, TextField } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FacebookIcon, FacebookShareButton,  WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon} from "react-share";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { useNavigate,  useLocation } from 'react-router-dom';





const Blogdetail=()=>
{

    

 const [isloading,setIsloading]=useState(true);

 const [slider, setSlider]=useState([]);

 const [blog, setblog]=useState([]);
 const [userdata, setuserdata]=useState([])

 const navigate = useNavigate()
 
 const Baseurl = process.env.REACT_APP_BASE_URL;

 const location = useLocation();

 const options = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};

const posteddate = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const customButton=
 {

    backgroundColor:'var(--primary)',
    color:'#000000',
    fontSize:'18px',
    padding:'12px 24px',
    fontWeight: 600,
    textTransform:'none',
    marginBlock:'10px',
    width:'100%',
    letterSpacing: '0.8px',  
    '&:hover': {
    backgroundColor:'var(--primary)',
 },
 }

 const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: '#ffffff',
  fontSize:'12px',
  fontWeight:'500',
  fontFamily:'var(--fontfamily)',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyleNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  ariaCurrent:"page",
  fontSize:'12px',
  color:"var(--primary)",
  fontWeight:'500',
  fontFamily:'var(--fontfamily)',
  '&:hover': {
    textDecoration: 'underline',
  },
}));


const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const [openSnackbar1, setopenSnackbar1]=useState({

 openSnackbar: false,
 vertical: 'top',
 horizontal: 'center',
});


const { vertical, horizontal, openSnackbar } = openSnackbar1;

const handleClose = (reason) => {
 if (reason === 'clickaway') {
   return;
 }
 setopenSnackbar1({...openSnackbar1, openSnackbar:false});

};



const [openSnackbar2, setopenSnackbar2]=useState({

 opentoast: false,
 verticaltoast: 'top',
 horizontaltoast: 'center',
});


const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;


const handleCloseToast = (reason) => {
 if (reason === 'clickaway') {
   return;
 }
 setopenSnackbar2({...openSnackbar2, opentoast:false});

};

const [user, setuser] = useState(
 {
     username:"",
     phone:"",
     email:"",
 }
)

const responsive = {
        
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1
  }
};


const [errors, setErrors] = useState()

const [apierror, setapierror]=useState()

const [blogTitle, setblogTitle]=useState()


const handleinput=(e)=>
{

   const name= e.target.name;
   const value=e.target.value;

   const newErrors = {}

   if (name === 'username' && value.length === 0) {
     newErrors.username = 'This field is required'
   } else {
     newErrors.username = ''
   }
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (name === 'email' && value.length === 0) {
     newErrors.email = 'This field is required'
   }
   else if(name === 'email' && !emailPattern.test(value)){
        newErrors.email='Please enter valid email'
   }
   else {
     newErrors.email = ''
   }


   if (name === 'phone' && value.length === 0) {
     newErrors.phone = 'This field is required'
   } 

   else if(name === 'phone' && value.length !== 10) {
     newErrors.phone='Please enter valid phone number'
   }
   else {
     newErrors.phone = ''
   }

   if (
     newErrors.username === '' &&
     newErrors.email === '' &&
     newErrors.phone === '' 
   ) {
     setErrors('')
   } else {
    
     setErrors(newErrors)
   }


   setuser({...user, [name]:value})
}

const handlesubmit=(e,newState)=>
{

 const apiurl = `${Baseurl}/signin`;

  e.preventDefault();
  
  const newErrors={}

    if(user.username==="")
    {
        newErrors.username='This field is required'
    }
    else{
        newErrors.username=''
    }
    
    const newemail=user.email;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if(user.email.length===0)
    {
        newErrors.email='This field is required'
    }

    else  if (!emailPattern.test(newemail)) {
    
        newErrors.email='Please enter valid email'
    }
    else
    {
        newErrors.email=''
    }

    if(user.phone.length===0)
    {
        newErrors.phone='This field is required'
    }
    else if(user.phone.length!==10)
    {
        newErrors.phone='Please enter valid phone number'
    }
    else
    {
        newErrors.phone=''
    }


    if (
    newErrors.username === '' &&
    newErrors.email === '' &&
    newErrors.phone === '' 
    )
 {
   const formdata = new FormData();

   formdata.append("name", user.username);
   formdata.append("email", user.email);
   formdata.append("phone", user.phone);
   formdata.append("page_name", "Blog Page");

   const requestOptions = {
     method: "POST",
     body: formdata,
     redirect: "follow"
   };

   fetch(apiurl, requestOptions)
     .then((response) => response.text())
     .then((result) => {

       const data= JSON.parse(result);

       if(data.status==='success')
       {

         setopenSnackbar1({ ...newState, openSnackbar: true });
         setErrors({})
         setuser({username:"", phone:"", email:""})
       }

       else 
       {
          setapierror('There is some technical issue!')
          setopenSnackbar2({ ...newState, opentoast: true });
       }

     })
 }
 else {
    
     setErrors(newErrors)
   }

}

const Similar=(Id)=>
{
  
  localStorage.setItem('blogId', Id)
  window.scrollTo({ top: 0, behavior:'instant' });
  setIsloading(true)

}

    useEffect(()=>
    {
  
       const Id=localStorage.getItem('blogId')

       const apiurl = `${Baseurl}/blog/${Id}`

       const apiurl1 = `${Baseurl}/user`
   
       const fetchdata= async()=>
       {
        
          try {
   
         const response = await fetch(apiurl);
         const data = await response.json();
         if(data.status==='success')
         {
            setSlider(data.similar_blogs)    
            setblog([data.data]) 
            setblogTitle(data.data.blog_title)
            console.log(data.similar_blogs)
             
         }
       
         const response1 = await fetch(apiurl1);
         const datas = await response1.json();

         if(datas.status==='success')
         {
            setuserdata(datas.data);
         }
         setIsloading(false)
            
         }catch(error)
         {
           console.log(error)
           setIsloading(false)
         }
       }
   
        if(isloading)
        {
           fetchdata();
        }
    },[isloading])
   
    const [showContent, setShowContent] = useState(false);


 const handlePost=(blog_category)=>
 {
    localStorage.setItem('category', blog_category);
    navigate('/blogs');
 }

 const handleImageLoad = () => {
  setShowContent(true);
};

const useFullCanonicalUrl = () => {
  const location = useLocation();
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return `${baseUrl}${location.pathname}${location.search}`;
};

const canonicalUrl = useFullCanonicalUrl();

    return (
        <>

              <Helmet>

              <meta name="title" content={blog && blog[0]?.meta_title}/>
              <meta name="description" content={blog && blog[0]?.meta_description}/>
              <link rel="canonical" href={canonicalUrl} />
              </Helmet>
              
            
              <section  style={{backgroundColor:'var(--secondary)'}} >
            <Container maxWidth='lg'>
            {blogTitle && <>

              <Breadcrumbs aria-label="breadcrumb">
            <StyledNavLink to={'/blogs'}>
               Blogs
            </StyledNavLink>

            <StyleNavLink
             to={`/blogs/${encodeURIComponent(blogTitle.replace(/\s+/g, '-').replace(/[/]/g, '-').replace(/\?/g, '')).toLocaleLowerCase()}`}
            >
              {blogTitle}
            </StyleNavLink>
            
          </Breadcrumbs>
          </>
    }
           
           
         <Box className='section'> 
       
            {blog && blog.map((cur=>
                {
                   

                    return(
                        <>
                            <h1 className='heading_title white' style={{margin: isSmscreen?'0px 0px 12px':'0px 0px 20px'}}>{cur.blog_title}</h1> 
                            <div><LazyLoadImage src={cur.banner_image} alt='Banner image' className='banner_image'   loading='lazy'  effect='blur'  onLoad={() => handleImageLoad()} /></div>
           
                      </>
                    )
                }))}
           
                 
            </Box>
        
          </Container>
          </section>

          {showContent && (
             
             <>
            <Container maxWidth='lg' style={{paddingTop:'20px', display:'flex',justifyContent:'space-between',gridColumnGap:'30px'}}>

          {blog && blog.map((cur=>
                {
 
                  const image = userdata.filter((data) => data.created_by === cur.created_by && data.user_image);

                  const formattedDateTime = new Date(cur.created_on).toLocaleString('en-IN', posteddate);
                    return(
                        <>
                       

                        <div style={{display:'flex', gridColumnGap:'20px',alignItems:'center'}}>
                          <Avatar src={image[0]?.user_image} alt='published'/>
                      
                          <div style={{display:'grid', gridRowGap:'4px'}}>
                              <div className='header_title' style={{fontWeight:'600'}}>Posted By: {cur.created_by}</div>
                              <div className='footer_font'>{formattedDateTime} | Read in 4 min</div>
                          </div>

                        </div>

               <div className="sharelink">
                   
                   <div className='footer_fonts'>Share</div>

                  <FacebookShareButton url={window.location.href}>
                  <FacebookIcon size={24} round />
                  </FacebookShareButton>
                  <WhatsappShareButton url={window.location.href}>
                  <WhatsappIcon size={24} round  />
                  </WhatsappShareButton>
                  <TelegramShareButton url={window.location.href}>
                  <TelegramIcon size={24} round />
                  </TelegramShareButton>

                  </div>
           
                      </>
                    )
                }))}

            
          </Container>
          </>
          )}

          <section style={{paddingTop:isSmscreen?"20px":"30px"}}>
            <Container maxWidth='lg'>

              <Box>               
              <Grid container rowSpacing={2} columnSpacing={{xs:4, sm:6,md:8}} display='flex' justifyContent='space-between'>
             
                 <Grid item xs={12} sm={isSmscreen?12:8} md={8}>
                 
                 {showContent && (
                  <>
                 {blog && blog.map((cur=>
                {
                    const cleanedDescription = cur.blog_description.replace(/<p><br\s?\/?><\/p>|<h[1-6]><br\s?\/?><\/h[1-6]>/g, '');
                    return(
                        <>
                        <div className='line-height'>
                            
                            {typeof cur.blog_description === 'string' ? (
                                <span  dangerouslySetInnerHTML={{ __html: cleanedDescription}} />
                            ) : (
                                cur.blog_description
                            )}
                            </div>
                           
                      </>
                    )
                }))}
               </>
                 )}
                 </Grid>
                
              <Grid  item xs={12} sm={isSmscreen?12:4} md={4}>
     
              {showContent && (
                <>

              <div style={{padding:isSmscreen?"0px 0px 30px 10px":"0px 0px 40px 10px"}}>
                   <div className='title' style={{fontWeight:'600',margin:isSmscreen?'0px 0px 10px':'0px 0px 10px'}}>Popular Category</div>

                   <div className='line-height header_title uxhover' style={{cursor:'pointer'}} onClick={()=>handlePost('Design')}>Design</div>
                   <div className='line-height header_title uxhover' style={{cursor:'pointer'}} onClick={()=>handlePost('Development')}>Development</div>
                   <div className='line-height header_title uxhover' style={{cursor:'pointer'}}  onClick={()=>handlePost('Marketing')}>Marketing</div>
                   <div className='line-height header_title uxhover' style={{cursor:'pointer'}} onClick={()=>handlePost('Server')}>Server</div>
                   <div className='line-height header_title uxhover' style={{cursor:'pointer'}}  onClick={()=>handlePost('Technology')}>Technology</div>

                </div>

                <div style={{backgroundColor:'#FFFCEC', padding:'20px',display:'grid', gridRowGap:'10px'}}>

                <h3 className='page_title' style={{margin:'0px 0px 10px',fontSize:'24px',lineHeight:'30px'}}> Do you want to scale up your business? </h3> 

                <Grid item xs={12} sm={12} md={12}>

                <div className="form_title">
              <label className="label-form" htmlFor="username">Full name <span style={{color:"red"}}>*</span></label>
                <TextField className="input-form" type="text" value={user.username} name="username" onChange={handleinput} placeholder="Devid Wonder"
                  error={Boolean(errors?.username)}
                  helperText={errors?.username}
                />
                </div>

                </Grid>
                <Grid item  xs={12} sm={12} md={12}>
                <div className="form_title">
                <label className="label-form" htmlFor="email">Email address <span style={{color:"red"}}>*</span></label>
               
                <TextField className="input-form" type="text" value={user.email} name="email" onChange={handleinput} placeholder="example@gmail.com" 
                   error={Boolean(errors?.email)}
                   helperText={errors?.email}
                />
                </div>
                </Grid>
                <Grid item  xs={12} sm={12} md={12}>
                <div className="form_title">
                <label className="label-form" htmlFor="phone">Phone number <span style={{color:"red"}}>*</span></label>

                <TextField className="input-form" type="number" value={user.phone} name="phone" onChange={handleinput} placeholder="+91 9876543210"
                  error={Boolean(errors?.phone)}
                  helperText={errors?.phone}
                />
                </div>
                </Grid>
              <Grid item  xs={12} sm={12} md={12}>
               
                <Button style={customButton} className="primary_button" onClick={(e)=>handlesubmit(e,{vertical: 'top', horizontal: 'center' })}>Get Started</Button>

                </Grid>
                </div>
                  
                </>
              )}
                </Grid>


              </Grid>

            {slider.length!==0 &&  <h3 className='page_title' style={{margin:'20px 0px 20px',fontSize:'26px',lineHeight:'30px'}}>Similar Post</h3>} 

              <Carousel
                responsive={responsive}
                className="developcarousel"
            >
                {slider && slider.map((slide, index) => {
                    const cleanedDescription = slide.blog_description.replace(/<p><br\s?\/?><\/p>|<h[1-6]><br\s?\/?><\/h[1-6]>/g, '');
                    return (
                        <div key={index} className="box-shadow p-20 similar_blog" onClick={()=>Similar(slide._id)}>
                            <LazyLoadImage
                                className='similar_blog_image'
                                src={slide.featured_image}
                                alt='blog'
                                loading="lazy"
                    />
                    <div>
                        <div className="similar_blog_title">
                             {slide.blog_title?.length > 60 ? `${slide.blog_title.substring(0, 60)}...` : slide.blog_title} 
                        </div>
                        <div className="footer_fonts" style={{fontWeight:'400'}}>
                             <span dangerouslySetInnerHTML={{ __html: slide.blog_description?.length > 150 ? `${cleanedDescription.substring(0, 150)}...` : cleanedDescription }} /> 
                        </div>
                    </div>

                </div>
            );
        })}
    </Carousel>

           
            </Box>

            <Stack spacing={2} sx={{ width: '100%' }}>
            
            <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert  severity="success" sx={{ width: '100%' }}>
                    Thank you for your submitting. Will get contact soon shortly.
                    </Alert>
                </Snackbar>

            </Stack>  

            <Stack spacing={2} sx={{ width: '100%' }}>

                <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                <Alert  severity="error" sx={{ width: '100%' }} >
                    {apierror}
                    </Alert>
                </Snackbar>

            </Stack>

          </Container>
          </section>           
  
        </>
    )
}

export default Blogdetail