import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
       


const Choose=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
     const isLargeScreen = useMediaQuery('(min-width:1200px)')

     const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: isSmscreen?'14px 12px':'30px 20px',
        borderRadius: '8px',
        cursor: 'pointer',
        display:'flex',
        alignItems:'center',
        gridColumnGap:'12px',
        width:'100vw'
      }));

    return(
        <>

          <section  style={{backgroundColor:'var(--secondary)'}}>
          {isLargeScreen? <div className="primarycontainer"> 
          <Grid container>
           <Grid item xs={12} sm={isSmscreen?12:5.5} md={5.5} style={{padding:'80px 20px 80px 0px',position:'relative'}}>

            <h3 className="heading_title white" style={{margin:'0px 0px 12px'}}>Why should you choose us?</h3>
            <p className="title white" style={{padding:'0px 0px 8px',marginBlock:'0px'}}>We are an industry-leading company that values honesty, integrity, and efficiency building.</p>
           
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="Benefits of Choosing Us"
                    loading="lazy"
                 
                />
             
             <Box>
            <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:2,md:2}} display="flex" justifyContent="space-between" marginTop="40px">
          
            <Grid item xs={12} sm={6} md={6} display='flex'>
            
             <Item>
             
             <LazyLoadImage
                    src="/bill.svg"
                    alt="Transparent Process & Billing"
                    loading="lazy"
                  
                />
                
                <div className="footer_font" style={{fontWeight:'600'}}>Transparent Process & Billing</div>
             </Item>
          

            </Grid>
            

                <Grid item xs={12} sm={6} md={6} display='flex'>

                <Item>
             <LazyLoadImage
                    
                    src="/Frame.svg"
                    alt="Dedicated Account Manager"
                    loading="lazy"
                  
                />
                <div className="footer_font" style={{fontWeight:'600'}}>Dedicated Account Manager</div>
             </Item>
                

            </Grid>
            <Grid item xs={12} sm={6} md={6} display='flex'>
            
           
            <Item>
             <LazyLoadImage
                   
                    src="/result.svg"
                    alt="High-Quality Output"
                    loading="lazy"
                
                />
                <div className="footer_font" style={{fontWeight:'600'}}>High-Quality Output</div>
             </Item>

            </Grid>
            

                <Grid item xs={12} sm={6} md={6} display='flex'>

              
                <Item>
             <LazyLoadImage
                
                    src="/Calender.svg"
                    alt="Weekly Reports"
                    loading="lazy"
                  
                />
                <div className="footer_font" style={{fontWeight:'600'}}>Weekly Reports</div>
             </Item>
                

            </Grid>

            <Grid item xs={12} sm={6} md={6} display='flex'>
            
            <Item>
            <LazyLoadImage
                   src="/Support.svg"
                   alt="Client Centered Approach"
                   loading="lazy"
              
               />
               <div className="footer_font" style={{fontWeight:'600'}}>Client Centered Approach</div>
            </Item>
         

           </Grid>

            </Grid>
            </Box>
            </Grid>

            <Grid item xs={12} sm={isSmscreen?12:6.5} md={6.5} display='flex' justifyContent={isSmscreen?'center':'flex-end'} style={isSmscreen?{paddingBottom:'30px'}:{}} >
         
            <LazyLoadImage
                     className="image_contains"
                     style={{aspectRatio:1}}
                   src="/choose.png"
                   alt="Benefits of Choosing Us"
                   loading="lazy"
                 
               />
             </Grid>
            </Grid>
          </div> :
          <Container maxWidth='lg' style={isSmscreen ?{paddingRight:'16px'}:{paddingRight:'0px'}}>
            
           <Grid container rowSpacing={{xs:6,md:0,sm:isSmscreen? 6:0}} >
           <Grid item xs={12} sm={isSmscreen?12:6} md={6} style={{backgroundColor:'var(--secondary)',padding: isSmscreen?'80px 0px 0px 0px':'80px 20px 80px 0px',position:'relative'}}>

            <h3 className="heading_title white" style={{margin:'0px 0px 12px'}}>Why should you choose us?</h3>
            <p className="title white" style={{padding:'0px 0px 8px',marginBlock:'0px',}}>We are an industry-leading company that values honesty, integrity, and efficiency Building.</p>
             
            <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="Benefits of Choosing Us"
                    loading="lazy"
                  
                />

             <Box>
            <Grid container rowSpacing={2} columnSpacing={{xs:1,sm:2,md:2}} display="flex" justifyContent="space-between" marginTop={isSmscreen?"20px":"40px"}>
          
            <Grid item xs={12} sm={12} md={6} display='flex'>
            
             <Item>
               
             <LazyLoadImage
                    
                    src="/bill.svg"
                    alt="Transparent Process & Billing"
                    loading="lazy"
            
                />
                
                <div className="footer_font" style={{fontWeight:'600'}}>Transparent Process & Billing</div>
             </Item>
          

            </Grid>
            

                <Grid item xs={12} sm={12} md={6} display='flex'>

                <Item>
             <LazyLoadImage
                  
                    src="/Frame.svg"
                    alt="Dedicated Account Manager"
                    loading="lazy"
                 
                />
                <div className="footer_font" style={{fontWeight:'600'}}>Dedicated Account Manager</div>
             </Item>
                

            </Grid>
            <Grid item xs={12} sm={12} md={6} display='flex'>
            
           
            <Item>
             <LazyLoadImage
                  
                    src="/result.svg"
                    alt="High-Quality Output"
                    loading="lazy"
                  
                />
                <div className="footer_font" style={{fontWeight:'600'}}>High-Quality Output</div>
             </Item>

            </Grid>
            

                <Grid item xs={12} sm={12} md={6} display='flex'>

              
                <Item>
             <LazyLoadImage
                  
                    src="/Calender.svg"
                    alt="Weekly Reports"
                    loading="lazy"
                   
                />
                <div className="footer_font" style={{fontWeight:'600'}}>Weekly Reports</div>
             </Item>
                

            </Grid>

            <Grid item xs={12} sm={12} md={6} display='flex'>
            
            <Item>
            <LazyLoadImage
             
                   src="/Support.svg"
                   alt="Client Centered Approach"
                   loading="lazy"
                 
               />
               <div className="footer_font" style={{fontWeight:'600'}}>Client Centered Approach</div>
            </Item>
         

           </Grid>

            </Grid>
            </Box>
            </Grid>

            <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'flex-end'} style={isSmscreen?{paddingBottom:'80px'}:{}} >
         

            <LazyLoadImage
                  className="image_contains"
                  style={{aspectRatio:1}}
                   src="/choose.png"
                   alt="Benefits of Choosing Us"
                   loading="lazy"
               
               />
              
        
             </Grid>
            </Grid>
           </Container>
}
           </section>
        </>
    )
}

export default Choose;

/*  <LazyLoadImage
                className='aboutimage'
                src="/fiver-thumbnails.png"
                alt="testimonial"
            />
            */