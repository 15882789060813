import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Mobile from "./Mobile";
import Appdevelopment from "./Appdevelopment";
import Lifecycle from "./Lifecycle";
import Project from "./Project";
import Niches from "./Niches";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';

const Mobileapp=()=>
{

    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();


    return (
        <>
          
          <Helmet>

        <link rel="canonical" href={canonicalUrl} />
        </Helmet>
         
            <div className="content">
            <Header/>
             <Mobile/>
             <Appdevelopment/>
             <Lifecycle/>
             <Niches/>
             <Project/>
            <Footer/>
            </div>
        
        </>
    )
}

export default Mobileapp;