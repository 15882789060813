import React from "react";
import { Container } from "@mui/material";

const Privacy=()=>
{
    return (
        <>
         
         <section style={{backgroundColor:'var(--secondary)'}}>
           
           <Container maxWidth='lg'>
               <h1 className="hero_title text-center white" style={{marginBlock:'0px',paddingBlock:'80px'}}>Privacy <span style={{color:'var(--primary)'}}>Policy</span></h1>
               </Container>
       </section>


       <section className="section">
       <Container maxWidth='lg'>

        <div>

        <span className="primary_title semibold mb-40"> Privacy policy</span>
     
        <p className="p-0 m-0 line-height mt-10 home_font">
            The Wethink Android app is built as a Free app in Google Play Store and this page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
            </p>
            <p className="p-0 mt-20 line-height home_font">
            If you choose to use our Service, then you agree to the collection and use of information in
            relation to this policy. The Personal Information that we collect is used for providing and
            improving the Service. We will not use or share your information with anyone except as
            described in this Privacy Policy.  <br></br>
           

            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
            which are accessible at Wethink unless otherwise defined in this Privacy Policy.
            </p>
            <p className="p-0 mt-20 line-height home_font">
                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                which are accessible at Wethink unless otherwise defined in this Privacy Policy.
            </p>
      

        <br>
        </br>

        <div className="mt-20">
            <p className="primary_title mt-20 line-height">Information Collection and Use</p>
            <p className="p-0  mt-10 line-height home_font">
            For a better experience, while using our Service, we may require you to provide us with certain
            personally identifiable information, including but not limited to Name, Mobile Number, Email &amp;
            Photo. The information that we request will be retained by us and used as described in this
            privacy policy.<br></br><br></br>

            The app does use third-party services that may collect information used to identify you.
            Link to the privacy policy of third-party service providers used by the app
            </p>
        </div>

        <div className="mt-40">
            <p className="primary_title line-height ">Log Data</p>
            <p className="p-0 mt-10 line-height home_font">
            We want to inform you that whenever you use our Service, in case of an error in the app we
            collect data and information (through third-party products) on your phone called Log Data. This
            Log Data may include information such as your device Internet Protocol (“IP”) address, device
            name, operating system version, the configuration of the app when utilizing our Service, the time
            and date of your use of the Service, and other statistics.
            </p>
        </div>
        <div className="mt-40">
            <p className="primary_title line-height ">Cookies</p>
            <p className="p-0 mt-10 line-height home_font">
            Cookies are files with a small amount of data that are commonly used as anonymous unique
            identifiers. These are sent to your browser from the websites that you visit and are stored on your
            device&#39;s internal memory.<br></br><br></br>
            
            This Service does not use these “cookies” explicitly. However, the app may use third-party code
            and libraries that use “cookies” to collect information and improve their services. You have the
            option to either accept or refuse these cookies and know when a cookie is being sent to your
            device. If you choose to refuse our cookies, you may not be able to use some portions of this
            Service.
            </p>
        </div>     
        <div className="mt-40">
            <p className="primary_title line-height">Service Providers</p>
            <p className="p-0 mt-10 line-height home_font">
            We may employ third-party companies and individuals due to the following reasons
            <ul>
                <li>To facilitate our Service.</li>
                <li>To provide the Service on our behalf.</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            We want to inform users of this Service that these third parties have access to their Personal
            Information. The reason is to perform the tasks assigned to them on our behalf. However, they
            are obligated not to disclose or use the information for any other purpose.
            </p>
        </div>   
        <div className="mt-40">
            <p className="primary_title line-height">Security</p>
            <p className="p-0 mt-10 line-height home_font">
            We value your trust in providing us your Personal Information, thus we are striving to use
            commercially acceptable means of protecting it. But remember that no method of transmission
            over the internet, or method of electronic storage is 100% secure and reliable, and we cannot
            guarantee its absolute security.
            </p>
        </div>
        <div className="mt-40">
            <p className="primary_title line-height">Links to Other Sites</p>
            <p className="p-0 mt-10 line-height home_font">
            These Services do not address anyone under the age of 13. We do not knowingly collect
            personally identifiable information from children under 13 years of age. In the case we discover
            that a child under 13 has provided us with personal information, we immediately delete this from
            our servers. If you are a parent or guardian and you are aware that your child has provided us
            with personal information, please contact us so that we will be able to do the necessary actions.
            </p>
        </div>
        <div className="mt-40">
            <p className="primary_title line-height">Changes to this Privacy policy</p>
            <p className="p-0 mt-10 line-height home_font">
            We may update our Privacy Policy from time to time. Thus, you are advised to review this page
            periodically for any changes. We will notify you of any changes by posting the new Privacy
            Policy on this page.<br></br>
            This policy is effective as of 2021-12-07
            </p>
        </div>
        <div className="mt-40">
            <p className="primary_title line-height ">Contact US</p>
            <p className="p-0 mt-10 line-height home_font">
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us
            at support@wethink.co.in.
            </p>
        </div>
        </div>
    </Container>
</section>
        
        </>
    )
}

export default Privacy;