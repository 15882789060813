import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Uidesign from "./Uidesign";
import Uxdevelopment from "./Uxdevelopment";
import Team from "./Team";
import Uxservices from "./Uxservices";
import Experience from "./Experience";
import GlimpseUI from "./GlimpseUI";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';



const UXdesign=()=>
{


    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>
         
         <Helmet>

          <link rel="canonical" href={canonicalUrl} />
          </Helmet>
           
            <div className="content">

            <Header/>
             <Uidesign/>
             <Uxdevelopment/>
             <Uxservices/>
             <Experience/>
             <GlimpseUI/>
            <Team/>
            <Footer/>

            </div>
            
         
        </>
    )
}

export default UXdesign;