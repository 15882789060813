import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Wordpress from "./Wordpress";
import Advantage from "./Advantage";
import Choose from "../../Home/Choose";
import Premium from "./Premium";
import Wordpressservices from "./Wordpressservices";
import Ownwebsite from "./Ownwebsite";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';



const Wordpressservice=()=>
{

    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>

            <Helmet>

            <link rel="canonical" href={canonicalUrl} />
            </Helmet>
         
            <div className="content">

            <Header/>
             <Wordpress/>
            <Advantage/>
            <Wordpressservices/>
            <Ownwebsite/>
             <Choose/>
             <Premium/>
            <Footer/>

            </div>
            
         
        </>
    )
}

export default Wordpressservice;