import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Stories from "./Stories";
import Mission from "./mission";
import Superheroes from "./superheroes";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';


const About=()=>
{
  
    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>
          
          <Helmet>

          <link rel="canonical" href={canonicalUrl} />
          </Helmet>
           
            <div className="content">
             <Header/>
              <Stories/>
              <Mission/>
              <Superheroes/>

              <Footer/>
            </div>


        </>
    )
}
export default About;