import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";      
import { motion } from "framer-motion"; 
import EastIcon from '@mui/icons-material/East'; 

const Designerservices=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const containerContent = {
        visible: { x: 0, opacity: 1, transition: { duration: 1 } },
        hidden: { x: -100, opacity: 0 }
      };
    
      const containerImage = {
        visible: {
          opacity: 1,
          x: 0,
          transition: { duration: 1, staggerChildren: 0.1, delayChildren: 0.1 },
        },
        hidden: { opacity: 0, x: 200 },
      };

    return(
        <>
             <section className="section" style={{background:'var(--primary)'}} >
          <Container maxWidth='lg'>
            
          <Box>
          <Grid container rowSpacing={4} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="space-between"  alignItems="center">

          <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
          
          <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerContent}
              viewport={{ once: false}}
          >

                     
               <LazyLoadImage
                       style={{ width: '100%' }}
                    src="/farmer.png"
                    alt="farmer-app"
                    loading="lazy"
             
                />
             </motion.div>

          
            </Grid>

         
         
         
          <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex'>
                
          <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerImage}
              viewport={{ once: false }}
          >
                <div className="demand">
                 
                 <div>
               
                <div className="footer_fonts" style={{fontWeight:'700'}}>UX DESIGN</div>

                <h3 className="heading_title" style={{marginBlock:'12px'}}>Grain Bank  Farmer-UI/UX Case Study</h3>

                <p className='title' style={{fontWeight:500,margin:'0px' ,lineHeight:'26px', color:'var(--secondary)'}}>

                The objective of this app to help farmer to store their crop in warehouse. It helps farmer to connect with buyer to sell their crop at best price. <br></br>
               
                <b>GrainBank</b>  app were most of people have easily access to now a day. <br></br><br></br>

                 This app also helps the farmer to get Advance loan for their crops which is stored in the warehouse.
                 
                </p>
                </div>

                 <div>

                   <div className="duration">
                      <div className="label footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Duration:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>45 Days</div>
                   </div>

                   <div className="duration">
                      <div className="label footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Platform:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>UX Design</div>
                   </div>

                   <div className="duration">
                      <div className="label footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Audience:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>B2C</div>
                   </div>

                   <div className="duration">
                      <div className="label footer_font"style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Tech Stack:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>Figma, Photoshop, UX Pin</div>
                   </div>


                 </div>

                   <div>

                   <div className="duration">
                      <div className="labels footer_font" style={{lineHeight:'22px'}}>Youtube</div>
                      <NavLink to='https://www.youtube.com/watch?v=S7Kkqo6cCi4' target="_blank" style={{width:'fit-content', lineHeight:'22px'}} className='home footer_font  label_data  uxhover' >View Portfolio</NavLink>
                   </div>

                   <div className="duration">
                      <div className="labels footer_font">Google Play:</div>
                      <NavLink to='https://play.google.com/store/apps/details?id=com.GrainbankFarmer.app' target="_blank" style={{width:'fit-content',lineHeight:'22px' }} className='home footer_font label_data  uxhover' >Download App</NavLink>
                   </div>
                     

                   </div>
                   
              
                </div>
                </motion.div>
            </Grid>
                          
         
              

            </Grid>
          </Box>
          </Container>
          </section>
       
        </>
    )
}

export default Designerservices;

























