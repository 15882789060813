import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
       


const Brand=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

    return(
        <>

          <section className="section">
          <Container maxWidth='lg'>
          <Box>
            
           <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="space-between" alignItems="center" >
           
           
           <Grid item xs={12} sm={isSmscreen?12:8} md={8}>

            <h3 className="section_title" style={{margin:isSmscreen?'0px 0px 20px':'0px'}}>We have work with these brand</h3>
   
            <div style={{display:'flex',gridColumnGap:'30px', gridRowGap:'20px', flexWrap:'wrap',justifyContent:'start',marginTop:isSmscreen?"20px":"30px", alignItems:"center"}}>


            <NavLink to="https://www.handdy.com/" target="_blank" style={{width:'fit-content' }} className="home">
            <div style={{ width: isSmscreen ? "120px" : "150px" }}>
                 <LazyLoadImage
                className='adidhiimage'
                src="/handdy.png"
                alt="handdy"
                loading="lazy"
            />
             </div>
       </NavLink>
          
        

        <NavLink to="https://play.google.com/store/apps/details?id=com.GrainbankFarmer.app" target="_blank" style={{width:'fit-content' }} className="home">
        <div style={{ width: isSmscreen ? "120px" : "150px" }}>
                 <LazyLoadImage
                 className='adidhiimage'
                src="/ergos.jpg"
                alt="ergos"
                loading="lazy"
            />
             </div>
             </NavLink>

             <NavLink to="https://www.salesgush.com/" target="_blank" style={{width:'fit-content' }} className="home">
             <div className="adidhiimage" style={{ width: isSmscreen ? "120px" : "150px" }}>
                 <LazyLoadImage
                src="/salesgush.png"
                alt="salesgush"
                loading="lazy"
            />
             </div>
       </NavLink>

       <NavLink to="http://fetchbig.co.nz/" target="_blank" style={{wordBreak:'break-all' }} className="home">
       <div className="adidhiimage" style={{ width: isSmscreen ? "120px" : "150px" }}>

                 <LazyLoadImage
             
                src="/fetchbig.png"
                alt="fetchbig"
                loading="lazy"
            />
          </div>
          </NavLink>

       <NavLink to="https://metalpe.in/" target="_blank" style={{width:'fit-content' }} className="home">
       <div style={{ width: isSmscreen ? "120px" : "150px" }}>
                 <LazyLoadImage
                className='adidhiimage'
                src="/metal_pay.png"
                alt="metal_pay"
                loading="lazy"
            />
             </div>
       </NavLink>
     
     
       <NavLink to="https://careercognizance.com/" target="_blank" style={{width:'fit-content' }} className="home">
                 <div  style={{ width: isSmscreen ? "120px" : "150px" }}>
                 <LazyLoadImage
               className="adidhiimage"
                src="/careercognizance.jpg"
                alt="careercognizance"
                loading="lazy"
            />
             </div>
       </NavLink>
        
       <NavLink to='https://adidhi.com/' target="_blank" style={{wordBreak:'break-all' }} className="home" >
       <div className="adidhiimage" style={{ width: isSmscreen ? "120px" : "150px" }}>
            <LazyLoadImage
              className='adidhiimage'
                src="/adidhi.jpg"
                alt="adidhi"
                loading="lazy"
            />
            </div>
            </NavLink>

            </div>

        </Grid>   

         <Grid item xs={12} sm={isSmscreen?12:4} md={4}>


            <h3 className="section_title" style={{margin:isSmscreen?'0px 0px 20px':'0px'}}>Our Partners</h3>

            <div style={{display:'flex',gridColumnGap:'30px', gridRowGap:'20px', flexWrap:'wrap', justifyContent:'start',marginTop:isSmscreen?"20px":"30px", alignItems:"center"}}>

            <NavLink style={{width:'fit-content' }} className="home">
                <div  className='adidhiimage'>
                <LazyLoadImage
                className='adidhiimage'
                src="/microsoft.png"
                alt="microsoft"
                loading="lazy"
            />
            </div>
            </NavLink>

               
            <NavLink  style={{width:'fit-content' }} className="home">
                <div  className='adidhiimage'>
                <LazyLoadImage
                className='adidhiimage'
                src="/aws.png"
                alt="aws"
                loading="lazy"
            />
            </div>
            </NavLink>

             <NavLink to="https://msg91.com/signup?utm_source=parasharcreativestudio" target="_blank" style={{width:'fit-content' }} className="home">
                <div  className='adidhiimage'>
                <LazyLoadImage
                className='adidhiimage'
                src="https://msg91.com/img/logo.svg"
                alt="msg91"
                loading="lazy"
            />
            </div>
            </NavLink>

            </div>


            </Grid>
   
       
   </Grid>
      
            </Box>

           </Container>
           </section>
        </>
    )
}

export default Brand;