import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
       


const Mobile=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))


    const [isImageLoaded, setImageLoaded] = useState(false);

    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = '/mobileapp.jpg';
        img.onload = () => {
            setImageLoaded(true);
            setShowContent(true);
        };
    }, []);

    

    return(
        <> 

          <section className="section_title" style={{background:'#18143B'}}>
          <Container maxWidth='lg' >
          <Box>
           <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:isSmscreen?4:6,md:6}} display="flex" justifyContent="space-between" alignItems='center' >

               
       <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' >
     
       {showContent && (
        <div>

        <h1 className="hero_title white" style={isSmscreen? {paddingTop:'20px',margin:'0px'}:{margin:'0px'}}>
          Ultimate <span style={{color:'var(--primary)'}}>Business</span> Solution Application
        </h1>
        <h2 className="secondary_title white" style={{marginBottom:'0px'}}>
          Your dream deserves more than a place in your imagination
        </h2>
        </div>
       )}
        </Grid>

            <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'flex-end'} style={isSmscreen?{paddingBottom:'30px'}:{}} >
           
         
             <div className="aboutimage"   style={{display:'flex',alignItems:'center'}}>

            <LazyLoadImage
                className="image_containers"
                src="/mobileapp.jpg"
                alt="mobile-app-development"
                loading="lazy"
                style={{
                    width: '100%',
                    height: "auto",
                    top: 0,
                    left: 0,
                    opacity: isImageLoaded ? 1 : 0, 
                    transition: 'opacity 0.3s ease-in-out',
                }}
            />
         </div>
            
       </Grid>
       
   </Grid>
      
            </Box>

           </Container>
           </section>
        </>
    )
}

export default Mobile;