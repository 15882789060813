import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';


const Advantage=()=>
{
  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))


     
 const [isImageLoaded, setImageLoaded] = useState(false);

 useEffect(() => {
     const img = new Image();
     img.src = '/Wordpress.png';
     img.onload = () => {
         setImageLoaded(true);
     };
 }, []);

 const [showContent, setShowContent] = useState(false);

 useEffect(() => {
     setShowContent(true);
 }, []);

    return (
        <>
          
           
            <section className="section">
            <Container maxWidth='lg'>

           <Box>
           <Grid container rowSpacing={2} columnSpacing={{xs:4,sm:6,md:8}} display="flex" justifyContent="space-between" alignItems="center" >
           

       <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent='center' >
       
       {showContent && (
        <div>
      
        <h1 className="page_title" style={{marginBottom:'0px'}}> The Advantages of Using Wordpress </h1>  

      
                    <div>
                        <p className="line-height">
                         WordPress is a free content management system (CMS) that allows you to create a beautiful website that truly represents your business. At We think, we appreciate the versatility and ease of use that the WordPress platform provides. It’s a great solution for many businesses as it can be used for everything from e-commerce sites to portfolio or professional websites. In fact, almost a quarter of websites use WordPress. Not sure whether you should make the switch? Here are three advantages of WordPress that truly make it stand out.
                        </p>
                        
                    <div style={{display:'flex',gridColumnGap:'10px' ,alignItems:'center'}}>

                        <CheckCircleRoundedIcon style={{transform:'scale(0.8)'}}/>
                        <p className="line-height" style={{margin:'6px 0px'}} >Mobile Responsiveness</p>

                    </div>

                    <div style={{display:'flex',gridColumnGap:'10px' ,alignItems:'center'}}>

                        <CheckCircleRoundedIcon style={{transform:'scale(0.8)'}}/>
                        <p className="line-height" style={{margin:'6px 0px'}}>Versatile</p>

                    </div>

                    <div style={{display:'flex',gridColumnGap:'10px' ,alignItems:'center'}}>

                        <CheckCircleRoundedIcon style={{transform:'scale(0.8)'}} />
                        <p className="line-height" style={{margin:'6px 0px'}}>Easy to use</p>

                    </div>

                    </div>

        </div>
       )}
        </Grid>

        <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex' justifyContent={isSmscreen?'center':'none'} alignItems='center'>
        {showContent && (
          
          <LazyLoadImage
       
           src="/Wordpress.png"
           alt="Wordpress"
           effect="blur"
            style={{
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: isImageLoaded ? 1 : 0, 
                transition: 'opacity 0.3s ease-in-out',
            }}
    
         />
        )}
        
          </Grid>

   </Grid>


            </Box>

          </Container>
          </section>

            


        </>
    )
}
export default Advantage;