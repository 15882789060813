
import { Box, Button, Grid, TextField } from "@mui/material";
import React, {useState} from "react";
import Container from '@mui/material/Container';
import { createTheme, useMediaQuery } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

const Project=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
 

    const customButton=
    {
  
    backgroundColor:'var(--primary)',
    color:'#000000',
  fontSize:'18px',
  padding:'12px 24px',
  fontWeight: 600,
  textTransform:'none',
  margin:isSmscreen?'10px 0px 20px':'20px 0px 30px',
  width:'100%',
  letterSpacing: '0.8px',  
    '&:hover': {
   backgroundColor:'var(--primary)',
    },
    }


    const Baseurl = process.env.REACT_APP_BASE_URL;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };

  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;


   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

  const [user, setuser] = useState(
    {
        username:"",
        phone:"",
        email:"",
        message:""
    }
  )



  const [errors, setErrors] = useState()

  const [apierror, setapierror]=useState()

  const handleinput=(e)=>
  {
 
      const name= e.target.name;
      const value=e.target.value;

      const newErrors = {}
  
      if (name === 'username' && value.length === 0) {
        newErrors.username = 'This field is required'
      } else {
        newErrors.username = ''
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (name === 'email' && value.length === 0) {
        newErrors.email = 'This field is required'
      }
      else if(name === 'email' && !emailPattern.test(value)){
           newErrors.email='Please enter valid email'
      }
      else {
        newErrors.email = ''
      }
  

      if (name === 'phone' && value.length === 0) {
        newErrors.phone = 'This field is required'
      } 

      else if(name === 'phone' && value.length !== 10) {
        newErrors.phone='Please enter valid phone number'
      }
      else {
        newErrors.phone = ''
      }

      if (name === 'message' && value.length === 0) {
        newErrors.message = 'This field is required'
      } else {
        newErrors.message = ''
      }
  
  
      if (
        newErrors.username === '' &&
        newErrors.email === '' &&
        newErrors.phone === '' 
      ) {
        setErrors('')
      } else {
       
        setErrors(newErrors)
      }
  

      setuser({...user, [name]:value})
  }

  const handlesubmit=(e,newState)=>
  {

    const apiurl = `${Baseurl}/signin`;

     e.preventDefault();
     
     const newErrors={}

    if(user.username==="")
    {
        newErrors.username='This field is required'
    }
    else{
        newErrors.username=''
    }
    
     const newemail=user.email;

     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
     if(user.email.length===0)
     {
         newErrors.email='This field is required'
     }

    else  if (!emailPattern.test(newemail)) {
    
        newErrors.email='Please enter valid email'
    }
    else
    {
        newErrors.email=''
    }

    if(user.phone.length===0)
    {
        newErrors.phone='This field is required'
    }
    else if(user.phone.length!==10)
    {
         newErrors.phone='Please enter valid phone number'
    }
    else
    {
        newErrors.phone=''
    }

   if (user.message.length==0)
   {
      newErrors.message='This field is required'
   }
   else
   {
     newErrors.message=''
   }

   if (
      newErrors.username === '' &&
      newErrors.email === '' &&
      newErrors.phone === '' &&
      newErrors.message === '' 
  )
    {
      const formdata = new FormData();

      formdata.append("name", user.username);
      formdata.append("email", user.email);
      formdata.append("phone", user.phone);
      formdata.append("message", user.message);
      formdata.append("page_name", "mobile app development");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      fetch(`${apiurl}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {

          const data= JSON.parse(result);

          if(data.status==='success')
          {

            setopenSnackbar1({ ...newState, openSnackbar: true });
            setErrors({})
            setuser({username:"", phone:"", email:"", message:"",
      })
          }

          else 
          {
             setapierror('There is some technical issue!')
             setopenSnackbar2({ ...newState, opentoast: true });
          }

        })
    }
    else {
       
        setErrors(newErrors)
      }
 
  }

    return(
        <>

           <section className="section" style={{backgroundColor:'#FFFCEC'}}>
           <Container maxWidth="lg">
             
               
            <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={{xs:2,sm:4,md:6}} columnSpacing={{ sm:4,md:6}}  display="flex" justifyContent="center" >
          
              <Grid item xs={12} sm={10} md={10}>
                   
                   <div className="project_form" style={{marginBlock:'0px'}}>
               <h3 className="heading_title" style={{marginBlock:'0px'}}>Let’s Talk About Your Project</h3>
                <p className="title" style={{margin:'0px', padding:isSmscreen?"0px 0px 20px":"4px 0px 40px"}}>Once you fill out this form. Our representatives will contact you within 24 hours. They will further guide you form thereon.</p>
                </div>

               <form>
              <Box sx={{width:"100%"}}>

              <Grid container rowSpacing={{xs:2,sm:3,md:4}} columnSpacing={{xs:2,sm:3, md:4}} columnGap={{xs:2, sm:0, md:0}} display="flex" justifyContent="center" >
            
              <Grid item xs={12} sm={6} md={5} >
            
             <div className="form_title">
              <label className="label-form" htmlFor="username">Full name <span style={{color:"red"}}>*</span></label>
                <TextField className="input-form" type="text" value={user.username} name="username" onChange={handleinput} placeholder="Devid Wonder"
                  error={Boolean(errors?.username)}
                  helperText={errors?.username}
                />
                </div>
                </Grid>
                <Grid item  xs={12} sm={6} md={5}>
                <div className="form_title">
                <label className="label-form" htmlFor="email">Email address <span style={{color:"red"}}>*</span></label>
               
                <TextField className="input-form" type="text" value={user.email} name="email" onChange={handleinput} placeholder="example@gmail.com" 
                   error={Boolean(errors?.email)}
                   helperText={errors?.email}
                />
                </div>
                </Grid>
                <Grid item  xs={12} sm={6} md={5}>
                <div className="form_title">
                <label className="label-form" htmlFor="phone">Phone number <span style={{color:"red"}}>*</span></label>

                <TextField className="input-form" type="number" value={user.phone} name="phone" onChange={handleinput} placeholder="+91 9876543210"
                  error={Boolean(errors?.phone)}
                  helperText={errors?.phone}
                />
                </div>
                </Grid>
              <Grid item  xs={12} sm={6} md={5}>
                <div className="form_title">
                <label className="label-form" htmlFor="message">Message <span style={{color:"red"}}>*</span></label>

                <TextField className="input-form" type='text' name="message" value={user.message}  onChange={handleinput} placeholder="Message" 
                 
                  error={Boolean(errors?.message)}
                   helperText={errors?.message}
                />
                </div>
              </Grid>
              </Grid>
             
              </Box>

              </form>
              </Grid>
              <Grid item xs={12} sm={10} md={8.4}>
              <Button style={customButton} className="primary_button" onClick={(e)=>handlesubmit(e,{vertical: 'top', horizontal: 'center' })}>Get Started</Button>
              </Grid>
            </Grid>

      
            <Stack spacing={2} sx={{ width: '100%' }}>
  
              <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
              <Alert  severity="success" sx={{ width: '100%' }}>
                     Thank you for your submitting. Will get contact soon shortly.
                    </Alert>
                </Snackbar>

              </Stack>  

              <Stack spacing={2} sx={{ width: '100%' }}>
  
                <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                <Alert  severity="error" sx={{ width: '100%' }} >
                    {apierror}
                      </Alert>
                  </Snackbar>

               </Stack>

            </Box>
             </Container>
             </section>
        </>
    )
}

export default Project;