import React, { useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { createTheme, useMediaQuery } from "@mui/material";

const Allservices=()=>
{

    const navigate =useNavigate()

  

   const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const web=()=>
 {
      window.scrollTo({ top: 0, behavior:'instant' });
       navigate('/our-expertise/web-services')
 }

 const uxdesign=()=>
 {
    window.scrollTo({ top: 0, behavior:'instant' });
    navigate('/our-expertise/ui-ux-design-services')
 }

 const ecommerce=()=>
 {
   window.scrollTo({ top: 0, behavior:'instant' });
    navigate('/our-expertise/e-commerce-solution')
 }

 const  mobileapp=()=>
 {
   window.scrollTo({ top: 0, behavior:'instant' });
     navigate('/our-expertise/mobile-app-development')
 }
 const wordpress=()=>
  {
       window.scrollTo({ top: 0, behavior:'instant' });
        navigate('/our-expertise/wordpress-website-develomemt')
  }

 const saas=()=>
 {
   window.scrollTo({ top: 0, behavior:'instant' });
    navigate('/our-expertise/saas-product-development')
 }

 const digital=()=>
 {
   window.scrollTo({ top: 0, behavior:'instant' });
    navigate('/our-expertise/digital-marketing')
 }

    return(
        <>
        <section className="section">
       
        <Container maxWidth='lg'>
         
        <div style={{margin:'0px 0px 20px'}}>
          <h3 className="heading_title" style={{marginBlock:'0px'}}>Our Services</h3>
          <p className="header_title" style={{margin:"6px 0px 22px", fontSize:"20px", fontWeight:"600"}}>Explore our services tailored to assist in your reinvention</p>
          </div>

      

        <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={{xs:2,sm:4,md:4}} columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start">
       
              
                        <Grid item xs={12} sm={8}  md={4}>
                            <div className="transportation" onClick={web}>
                          
                                    <LazyLoadImage  
                                    className="abstract"  
                                      src="/Why Should you choose 5.png"
                                     alt="web services"
                                     loading="lazy"
                                   
                                     />
                                   
                                  <div className="title" style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}} >Web Services</div>
                              
                            </div>
                            </Grid>
                            <Grid item xs={12} sm={8}  md={4}>
                            <div className="transportation" onClick={uxdesign}>
                          
                                    <LazyLoadImage  className="abstract"   
                                     src="/ui-ux-cover.jpg"
                                     alt="ux design"
                                     loading="lazy"
                                    
                                     />
                                    
                              
                                <div className="title" style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}}>UI/UX Design Services</div>
                              
                            </div>
                            </Grid>
                           
                            <Grid item xs={12} sm={8}  md={4}>
                            <div className="transportation" onClick={mobileapp}>
                          
                              
                                    <LazyLoadImage  className="abstract"   
                                     src="/mobileapp.jpg"
                                     alt="mobile-app-development"
                                     loading="lazy"
                                   
                                     />
                                   
                              
                                <div className="title"style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}} >Mobile App Development</div>
                              
                            </div>
                            </Grid>
                
                            <Grid item xs={12} sm={8}  md={4}>
                            <div className="transportation" onClick={digital}>
                          
                                    <LazyLoadImage  className="abstract"   
                                     src="/social.png"
                                     alt="digital marketing"
                                     loading="lazy"
                                    
                                     />
                              
                              
                                <div className="title" style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}}>Digital Marketing</div>
                              
                            </div>
                            </Grid>
                            <Grid item xs={12} sm={8}  md={4}>
                            <div className="transportation" onClick={wordpress}>
                          
                                    <LazyLoadImage  
                                    className="abstract"  
                                      src="/Why Should you choose 5.png"
                                     alt="wordpress website development services"
                                     loading="lazy"
                                   
                                     />
                                   
                                  <div className="title" style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}} >Wordpress Website Development Services</div>
                              
                            </div>
                            </Grid>
                  
             </Grid>
             </Box>
            
         
             </Container>
             </section>
        </>
    )
}
export default Allservices;

/*

            <Grid item xs={12} sm={6}  md={4}>
                            <div className="transportation" onClick={saas}>
                          
                              

                                    <LazyLoadImage  className="abstract"    src="/mobileapp.jpg"
                                     alt="saas-product-development"
                                     loading="lazy"/>
                                  
                              
                                <div className="title" style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}}>Saas Product Development</div>
                              
                            </div>
                            </Grid>

 <Grid item xs={12} sm={6}  md={4}>
                            <div className="transportation" onClick={ecommerce}>
                          
                                    <LazyLoadImage  className="abstract"    src="/mobileapp.jpg"
                                     alt="e-commerce services"
                                     loading="lazy"/>
                                    
                              
                                <div className="title" style={{padding:isSmscreen?'10px':'20px',fontWeight:'600'}}>E-Commerce Solution</div>
                              
                            </div>
                            </Grid>

<div>
             <NavLink to="/addblog"><button className="page_title gadget primary_button">Add New Blog</button></NavLink>
             </div>
             */