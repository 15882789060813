import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Digital from "./Digital";
import Digitalmatters from "./Digitalmatters";
import Digitalservices from "./Digitalservices";
import Digitalprojects from "./Digitalprojects";
import Testimonials from "./Testimonials";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';


const Digitalmarketing=()=>
{

    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>
         
         <Helmet>

            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        
            <div className="content">
            <Header/>

            <Digital/>
             <Digitalmatters/>
             <Digitalservices/>
             <Testimonials/>
             <Digitalprojects/>
         
            </div>
            <Footer/>
       
            
         
        </>
    )
}

export default Digitalmarketing;

/*
             */