import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";


const Mission=()=>
{
 
  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const [isImageLoaded, setImageLoaded] = useState(false);
 const [showContent, setShowContent] = useState(false);

 useEffect(() => {
     const img = new Image();
     img.src = '/mission-vision.png';
     img.onload = () => {
         setImageLoaded(true);
         setShowContent(true);
     };
 }, []);
 


    return (
        <>
          
           
           
          <section className="section">
            <Container maxWidth='lg'>
           
        
          
          <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="space-between" alignItems='center'>

            <Grid item xs={12} sm={isSmscreen?12:6} md={6} >
           
               
            <LazyLoadImage
                    src="/mission-vision.png"
                    alt="The WeThink Story"
                    loading="lazy"
                    style={{
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        opacity: isImageLoaded ? 1 : 0, 
                        transition: 'opacity 0.3s ease-in-out',
                    }}
                />

              </Grid>
            

                <Grid item xs={12} sm={isSmscreen?12:6} md={6} >

                {showContent && ( 
                  <>
                <div className="font_30 width-80 pb-70">

                      <span className="bold">Our vision</span>  is to create and connect with people through digital product.
                  </div>

                <h1 className="page_title" style={{margin:'0px'}}> Mission </h1>
                
                <LazyLoadImage
                    src="/Rectangle.svg"
                    alt="Our Vision"
                    loading="lazy"
                />

                <p className="line-height">In the age of digital-first and personalization, 
        all enterprises are focused on delivering offerings and features to market faster, 
        scale them smartly and operate highly efficiently to deliver excellent customer experiences.
         <br></br> <br></br>
         Our mission is to enable enterprises to rapidly adopt cutting edge technologies to deliver
          these business outcomes and maximize value from their infrastructure investments.</p>
          </>
              )}
              </Grid>


            </Grid>
       
          </Box>
          

          </Container>
          </section>

            


        </>
    )
}
export default Mission;