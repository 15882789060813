import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";
       


const Glimpses=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

    return(
        <>

          <section className="section">
          <Container maxWidth='lg'>
          <Box sx={{width:'100%'}}>
            <h3 className="heading_title" style={{textAlign:'center',margin:'0px 0px 12px'}}>Glimpses Of Our Past Works!</h3>

            <p className='title' style={{fontWeight:500, margin:'0px 0px 30px' ,lineHeight:'24px', color:'var(--secondary)',textAlign:'center'}}><b>Three years</b> of crafting sweet experiences with Research, Design & Development.</p>
           <Grid container rowSpacing={{xs:2,sm:4,md:4}} columnSpacing={{sm:4,md:6}} display="flex" justifyContent="space-between">

       <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
        
        <NavLink to="https://www.behance.net/gallery/76491863/Orchid-Mobile-app" className="home" target="_blank" aria-label="Orchid Mobile App">
       <LazyLoadImage
                  className="glimpseimage"
                src="/glimpses2.png"
                alt="Orchid"
                loading="lazy"
            
            />
    </NavLink>
        </Grid>
        

            <Grid item xs={12} sm={isSmscreen?12:6} md={6}>

            <NavLink to="https://www.instagram.com/adidhi.app/" className="home" target="_blank" aria-label="Adidhi App">
            <LazyLoadImage
               className="glimpseimage"
                src="/glimpses3.png"
                alt="Adidhi"
                loading="lazy"
               
            />
            </NavLink>
     
       </Grid>
       <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
        
       <NavLink  to="https://www.behance.net/gallery/68868913/Total-Cost-of-Ownership-Study-For-The-Hatchback" className="home" target="_blank" aria-label="Total-Cost-of-Ownership-Study-For-The-Hatchback" >
        <LazyLoadImage
             className="glimpseimage"
                 src="/glimpses4.png"
                 alt="Tri-Fold Brochure"
                 loading="lazy"
                
             />
        </NavLink>
         </Grid>
         
 
             <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
 
             <NavLink to="https://www.behance.net/gallery/156914443/On-Demand-Service-Booking-app-UX-Case-Study" className="home" target="_blank">
             <LazyLoadImage
               className="glimpseimage"
                 src="/glimpses1.png"
                 alt="On Road App"
                 loading="lazy"
                
             />
             </NavLink>
      
        </Grid>
       
   </Grid>
      
            </Box>

           </Container>
           </section>
        </>
    )
}

export default Glimpses;