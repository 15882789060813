
import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Blogdetail from './Blogdetail'

const Readblog = () => {
  return (
     
      <>
           
         
            <div className="content">
            <Header/>
             <Blogdetail/>
            </div>
            <Footer/>
            
      </>
  
  )
}

export default Readblog