import React from "react";
import { Container, Typography,  Box, IconButton } from '@mui/material';
import {NavLink} from "react-router-dom";
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../assests/wethinklogo.png";
import { createTheme, useMediaQuery } from "@mui/material";

const  Footer=() => {

  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))


 const baseUrl = window.location.origin
 const pdfUrl = `${baseUrl}/WeThink-Company.pdf`;

 const home=()=>
 {
   window.scrollTo({ top: 0, behavior: 'instant' });
   window.location.replace('/');
 }

 const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'instant' });
};



    return (
    
      <footer>
           
        <Box sx={{bgcolor:"#ffffff", width:"100%", flexGrow:1, pb:'50px',pt:'40px'}}>
        <Container maxWidth="lg">
        <Grid container display="flex" justifyContent="space-between" rowSpacing={2} columnSpacing={{xs:2,sm:2,md:4}} >

            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='column'  alignItems='start' >

              
                 <div className= {isSmscreen?"footer_fonts":"footer_font"} style={{lineHeight:'16px',paddingTop:'4px'}}>Trusted design & development studio based in Bangalore.</div>
                   
                  <div className="social_media">
                    <NavLink to ='https://www.youtube.com/@wethink-uxstudio1990' target="_blank" aria-label="youtube" className="home black"><YouTubeIcon style={{width: isSmscreen?'26px':'34px',height:isSmscreen?'26px':'34px'}} /></NavLink>
                    <NavLink to='https://www.facebook.com/We-think-1404048206480765/' target="_blank" aria-label="facebook" className="home black"><FacebookIcon style={{width: isSmscreen?'26px':'34px',height:isSmscreen?'26px':'34px'}}/></NavLink>
                    <NavLink to='https://www.instagram.com/wethink.co.in/' target="_blank" aria-label="instagram" className="home black"><InstagramIcon style={{width: isSmscreen?'26px':'34px',height:isSmscreen?'26px':'34px'}}/></NavLink>
                    <NavLink to='https://www.linkedin.com/company/wethink-co-in/' target="_blank" aria-label="linkedin" className="home black"><LinkedInIcon style={{width: isSmscreen?'26px':'34px',height:isSmscreen?'26px':'34px'}}/></NavLink>

                  </div>
               
            </Grid>
            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='column' alignItems='start'>

                <div className="footer_pagargraph" style={{ display:"flex", flexDirection:'column', alignItems:'start' }}>

                <div className="footer_primary_title" style={{fontWeight:'700',paddingBottom:'8px'}}>About us</div>
                <NavLink to="https://wethink.zohorecruit.com/careers"  target="_blank" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Career</NavLink>
                <NavLink to="/about-us" onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>About us</NavLink>
                <NavLink to="/contact-us" onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Contact us</NavLink>
                <NavLink   to={pdfUrl} target="_blank" rel="noopener noreferrer" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Corporate Deck</NavLink>
                </div>
            </Grid>

            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='column' alignItems='start'>

                <div className="footer_pagargraph" style={{ display:"flex", flexDirection:'column', alignItems:'start' }}>

                <div className="footer_primary_title" style={{fontWeight:'700',paddingBottom:'8px'}}>Get in Touch</div>
                 <div className= {isSmscreen?"footer_fonts":"footer_font"} style={{fontWeight:600}}>Sales inquiry 
                    </div>
                    <NavLink to="mailto:robin@wethink.co.in" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>
                    
                        robin@wethink.co.in
                        
                     </NavLink>

                    
                     <div className= {isSmscreen?"footer_fonts":"footer_font"} style={{fontWeight:600,paddingTop:'12px'}}>Product inquiry
                    </div>

                    <NavLink to="mailto:pavana@wethink.co.in" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>
                    
                      pavana@wethink.co.in
                       
                     </NavLink>
                     </div>

            </Grid>

            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='row' alignItems='start'>

                <div className="footer_pagargraph" style={{ display:"flex", flexDirection:'column', alignItems:'start'}}>

                <div className="footer_primary_title" style={{fontWeight:'700',paddingBottom:'8px'}}>Services</div>
                <NavLink to='/our-expertise/web-services' onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Web Services</NavLink>
                <NavLink to='/our-expertise/ui-ux-design-services' onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>UI/UX Design Services</NavLink>
                <NavLink to='/our-expertise/mobile-app-development' onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Mobile App Development</NavLink>
                <NavLink to='/our-expertise/digital-marketing' onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Digital Marketing</NavLink>
                <NavLink to='/our-expertise/wordpress-website-develomemt' onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Wordpress Website Development</NavLink>
                </div>

            </Grid>
       

         

            </Grid>
           
            </Container>

            <NavLink to="https://wa.link/ubho97" target="_blank" style={{display:'flex', justifyContent:'flex-end', position:'fixed',bottom:"20px", right:"20px", cursor:'pointer'}}>
              <LazyLoadImage className="whatsapp-img" src='/whatsapp.svg' alt="whatsapp"  loading="lazy"/>
              </NavLink>

            </Box>

              <div className="border_top">

            <Container maxWidth="lg">
               <div className="footer_terms">

                <div className="footer_fonts" style={{lineHeight:'20px'}}>
                &copy; Parashar Creative Studio. {new Date().getFullYear()}. All rights reserved.
                </div>
                 
                 <div className="footer_condition">
               <span><NavLink to='/terms-and-conditions' onClick={scrollToTop} className= "footer_fonts footer_link uxhover">Terms of Services</NavLink></span>
               <span><NavLink to='/privacy-policy' onClick={scrollToTop} className="footer_fonts footer_link uxhover">Privacy Policy</NavLink></span>
               <span><NavLink to='/return-policy' onClick={scrollToTop} className= "footer_fonts footer_link uxhover">Return Policy</NavLink></span>
      
            </div>
                 
               

               </div>
            </Container>
            </div>


        
          
           
      </footer>

    );
  }
  
  export default Footer;

  /*
                 <div><LazyLoadImage className="logo-img" src={logo} alt="WeThink" onClick={home} loading="lazy"/></div> (logo)


                <NavLink to='/services/saas-product-development' className="footer_title footer_fonts uxhover">Saas Product Development</NavLink>
                  <NavLink to='/services/e-commerce-solution' className="footer_title footer_fonts uxhover">e-Commerce Solution</NavLink>
 <NavLink to="tel:9937557441" className="footer_title footer_fonts uxhover">
                    
                        9937557441
                        
                     </NavLink>
            */
  
