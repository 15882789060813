import React from "react";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";

const Termsandcondition=()=>
{
    return (
        <>
            
            <section style={{backgroundColor:'var(--secondary)'}}>
           
            <Container maxWidth='lg'>
                <h1 className="hero_title text-center white" style={{marginBlock:'0px',paddingBlock:'80px'}}>Terms and <span style={{color:'var(--primary)'}}>Conditions</span></h1>
                </Container>
        </section>
            
            <section className="section">

           
            <Container maxWidth='lg'>
              
              <div>

              <span className="primary_title semibold"> 23rd of December, 2020</span>
              <p className="p-0 m-0 line-height mt-10 home_font">
                This page states the "Terms and conditions" under which you may use https://wethink.co.in, an online service to post and search employment opportunities and user resumes.
            </p>
            <p className="mt-20 line-height home_font"> 
                These Terms of Use include the Wethink Privacy Policy which is incorporated into these Terms of Use by reference. By using https://wethink.co.in, you are indicating your acceptance to be bound by these Terms and Conditions, including the Wethink Privacy Policy.
                If ‘You’ do not accept the Terms of Use stated herein, do not use the Web Site and its services. Wethink may revise these Terms of Use at any time by updating this posting. You should visit this page periodically to review the Terms of Use because they are binding on you. The terms "You" and "User" as used herein refer to all individuals and/or entities accessing this website for any reason.
                Copying or downloading or recreating or sharing passwords or sublicensing or sharing in any manner which is not in accordance with these terms, is a misuse of the platform or service and Wethink reserves its rights to act in such manner as to protect its loss of revenue or reputation or claim damages including stopping your service or access and reporting to relevant authorities. In the event you are found to be copying or misusing or transmitting or crawling any data or photographs or graphics or any information available on https://wethink.co.in for any purpose other than that being a bonafide Purpose, we reserve the right to take such action that we deem fit including stopping access and claiming damages.
            </p>
            <p className="mt-20 line-height home_font">
                The site is a public site with free access and assumes no liability for the quality and genuineness of responses. Wethink cannot monitor the responses that a person may receive in response to information he/she has displayed on the site. The individual/company would have to conduct its own background checks on the bonafide nature of all response(s).
                </p>
            <p className="mt-20 line-height home_font"> 
                You give us permission to use the information about actions that you have taken on wethink.co.nz in connection with ads, offers and other content (whether sponsored or not) that we display across our services, without any compensation to you. We use data and information about you to make relevant suggestions and recommendation to you and others.
                </p>
            <p className="mt-20 line-height home_font"> 
                The platform may contain links to third party websites, these links are provided solely as convenience to You and the presence of these links should not under any circumstances be considered as an endorsement of the contents of the same, if You chose to access these websites you do so at your own risk.
                </p>
            <p className="mt-20 line-height home_font"> 
                Wethink may revise these Terms at any time by posting an updated version to this Web page. You should visit this page periodically to review the most current Terms because they are binding on ‘You’.

            </p>
            <p className="mt-20 line-height home_font">
                This Privacy Policy applies to our website, https://wethink.co.in. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
            </p>
            <br>
            </br>

            <div className="mt-20">
            <p className="primary_title mt-20 line-height">Consent</p>
            <p className="p-0  mt-10 line-height home_font">
                By using our site, registering an account, you consent to this Terms of Service. <br></br><br></br>
                
                By accepting these terms and conditions, you agree to indemnify and otherwise hold harmless Wethink, its directors, officers, employers, agents, subsidiaries, affiliates and other partners from any direct, indirect, incidental, special, consequential or exemplary damages arising out of, relating to, or resulting from your use of the services obtained through https://wethink.co.in including but not limited to information provided by you or any other matter relating to Wethink. <br></br><br></br>
                
                If any dispute arises between a user/users and Wethink arising out of use of https://wethink.co.in or thereafter, in connection with the validity, interpretation, implementation or alleged breach of any provision of these Terms and Conditions, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by the Company. Decision of the arbitrator shall be final and binding on both the parties to the dispute. The place of arbitration shall be at Kannur, Kerala (India). <br></br><br></br>The Arbitration &amp; Conciliation Act, 1996 as amended, shall govern the arbitration proceedings.
            </p>
        </div>

        <div className="mt-40">

            <p className="primary_title line-height">Links to Other Websites</p>
            <p className="p-0 mt-10 line-height home_font">
                This Terms of Service applies only to the Services on this platform. The Services may contain links to other websites not operated or controlled by Wethink. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Terms of Service are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.
            </p>
        </div>


        <div className="mt-40">
            <p className="primary_title line-height">Cookies</p>
            <p className="p-0 mt-10 line-height home_font">
                Wethink uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies. By using our site or registering an account, you consent to the fact that we use cookies.
            </p>
        </div>

        <div className="mt-40">
            <p className="primary_title line-height">Changes to Our Terms and Conditions</p>
            <p className="p-0 mt-10 line-height home_font">
                You acknowledge and agree that Wethink may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at its sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically 
                inform when you stop using the Service. You acknowledge and agree that if Wethink disables access to your account, 
                you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account. <br></br> <br></br>

                If we decide to change our Terms and conditions, we will post those changes on this page, and/or update the Terms and conditions modification date below.
            </p>
        </div>
       
        <div className="mt-40">
            <p className="primary_title line-height">Modifications to Our website</p>
            <p className="p-0 mt-10 line-height home_font">
            Wethink reserves the right to modify, suspend or discontinue, temporarily or permanently, the website or any service to which it connects, with or without notice and without liability to you.

            </p>
        </div>

        
        <div className="mt-40">
            <p className="primary_title line-height">Updates to Our website</p>
            <p className="p-0 mt-10 line-height home_font">
                Wethink uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies. By using our site or registering an account, you consent to the fact that we use cookies.
                Updates may modify or delete certain features and/or functionalities of the website. You agree that Wethink has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the website to you.
            </p>
            <p className="p-0 mt-20 line-height">
                You further agree that all Updates will be: 
            <ul className="line-height home_font">
                <li> deemed to constitute an integral part of the website. </li> 
                <li>subject to the terms of service of this Agreement. </li>
            </ul>
            </p>
        </div>

        <div className="mt-40">
            <p className="primary_title line-height">Third-Party Services</p>
            <p className="p-0 mt-10 line-height home_font">
                We may display, include or make available third-party content or provide links to third-party websites or services. <br></br> <br></br>
                You acknowledge and agree that Wethink shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Wethink does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. <br></br><br></br>
                Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' ‘terms of service’.

            </p>
        </div>

         
        <div className="mt-40">
            <p className="primary_title line-height">Term and Termination</p>
            <p className="p-0 mt-10 line-height home_font" >
                This Agreement shall remain in effect until terminated by you or Wethink. <br></br><br></br>
                Wethink may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.<br></br><br></br>
                This Agreement will terminate immediately, without prior notice from Wethink, in the event that you fail to comply with any provision of this Agreement. <br></br><br></br>
                Termination of this Agreement will not limit any of Wethink’s rights or remedies at law in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.

            </p>
        </div>

                 
        <div className="mt-40">
            <p className="primary_title line-height">Severability</p>
            <p className="p-0 mt-10 line-height home_font">
            If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.

            </p>
        </div>
        <div className="mt-40">
            <p className="primary_title line-height">Amendments</p>
            <p className="p-0 mt-10 line-height home_font">
                Wethink reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. <br></br><br></br>
                By continuing to access or use our website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Wethink.

            </p>
        </div>

        <div className="mt-40">
            <p className="primary_title line-height">Entire Agreement</p>
            <p className="p-0 mt-10 line-height home_font">
            The Agreement constitutes the entire agreement between you and Wethink regarding your use of the website and supersedes all prior and contemporaneous written or oral agreements between you and Wethink. <br></br> <br></br>
            You may be subject to additional terms of service that apply when you use other Wethink’s services, which Wethink will provide to you at the time of such use.

            </p>
        </div>


        <div className="mt-40">
            <p className="primary_title line-height">Disclaimer</p>
            <p className="p-0 mt-10 line-height home_font">
                            Wethink is not responsible for any content, code or any other imprecision. <br></br> <br></br>
                Wethink does not provide warranties or guarantees. <br></br> <br></br>
                In no event shall Wethink be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice. 
            </p>
        </div>

        <div className="mt-40">
            <p className="primary_title line-height">Contact Us</p>
            <p className="p-0 mt-10 home_font line-height">
                Don't hesitate to contact us if you have any questions.
            </p>
            <p className="p-0  mt-10 line-height home_font">
                Via Email: <NavLink to="mailto:robin@wethink.co.in" className='home home_font'>robin@wethink.co.in </NavLink> <br></br>
                Via this Link: <NavLink to="https://wethink.co.in" className='home home_font'> https://wethink.co.in  </NavLink>
            </p>
        </div>
        



              </div>

                </Container>

            </section>

        </>
    )
}

export default Termsandcondition;

/*

       


     


       
*/