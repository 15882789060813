import React from "react";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { Box, Grid, createTheme, useMediaQuery} from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   textAlign: 'center',
   borderRadius:'12px',
  }));

const Contentskeleton=()=>
{

   const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });
  
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const arrayLength = 3;



  const item = Array.from({ length: arrayLength }, (_, index) => `Item ${index + 1}`);

      return (
        <>
                 <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={4} columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start" >
       
                {item.map((cur,index)=>
                {

    

                    return(
                        <>
                        <Grid item xs={10} sm={5}  md={4}>
                            <div className="transportation">
                          
                                <div><Skeleton height="30vh" borderRadius="10px 10px 0px 0px"/></div>
                                <div className="sarskeleton">
                                 <div className="footer_font"><Skeleton width='20vh'/></div>   
                                <div className="title">
                                  <Skeleton/>
                                </div>
                                <div className="describe">
                                 <Skeleton/>
                                 <Skeleton/>
                                 <Skeleton/>
                                </div>
                                  <Skeleton width='12vh'/>

                                </div>

                            </div>
                            </Grid>
                        </>
                    )
                })}
               
             </Grid>
             </Box>
            
            
        </>
      )

}

export default Contentskeleton;