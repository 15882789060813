
import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';       
import { motion } from "framer-motion";  
import { NavLink } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';

const Designer=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

     const containerContent = {
      visible: { x: 0, opacity: 1, transition: { duration: 1 } },
      hidden: { x: -100, opacity: 0 }
    };
  
    const containerImage = {
      visible: {
        opacity: 1,
        x: 0,
        transition: { duration: 1, staggerChildren: 0.1, delayChildren: 0.1 },
      },
      hidden: { opacity: 0, x: 200 },
    };


    return(
        <>
             <section className="section">
          <Container maxWidth='lg'>
            
          <Box sx={{width:'100%'}}>
          <Grid container rowSpacing={4} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="space-between"  alignItems="center">

          <Grid item xs={12} sm={isSmscreen?12:6} md={6} display='flex'>
                
          <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerContent}
              viewport={{ once: false}}
          >

                <div className="demand">
                 
                 <div>
               
                <div className="footer_fonts" style={{fontWeight:'700'}}>MOBILE APP DEVELOPMENT</div>

                <h3 className="heading_title" style={{marginBlock:'12px'}}>OnRoad On Demand Service Booking App</h3>

                <p className='title' style={{fontWeight:500,margin:'0px'}}>We have build a solution to help the user to find the on demand Services
                   near by them and book the services seamless.
                </p>
                </div>

                 <div>

                   <div className="duration">
                      <div className="label footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/> Duration:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>45 Days</div>
                   </div>

                   <div className="duration">
                      <div className="label footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Platform:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>iOS/Android</div>
                   </div>

                   <div className="duration">
                      <div className="label  footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Audience:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>B2C</div>
                   </div>

                   <div className="duration">
                      <div className="label footer_font" style={{lineHeight:'22px'}}><EastIcon className="eastIcon"/>Tech Stack:</div>
                      <div className="footer_font label_data " style={{lineHeight:'22px'}}>Python, Flutter, Figma, AWS</div>
                   </div>


                 </div>

                   <div>

                   <div className="duration">
                      <div className="labels footer_font" style={{lineHeight:'22px'}}>Behance:</div>
                      <NavLink to='https://www.behance.net/gallery/156914443/On-Demand-Service-Booking-app-UX-Case-Study' target="_blank" style={{width:'fit-content', lineHeight:'22px'}} className='home footer_font label_data  uxhover' >View Portfolio</NavLink>
                   </div>

                   <div className="duration">
                      <div className="labels footer_font" style={{lineHeight:'22px'}}>Google Play:</div>
                      <NavLink to='https://play.google.com/store/apps/details?id=onroad.buyer.app' target="_blank" style={{width:'fit-content',lineHeight:'22px' }} className='home footer_font label_data  uxhover' >Download App</NavLink>
                   </div>
                     

                   </div>
                   
              
                </div>
                </motion.div>
            </Grid>
                          
            <Grid item xs={12} sm={isSmscreen?12:6} md={6} >
          
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={containerImage}
              viewport={{ once:false}}
          >

             
               <LazyLoadImage
                    
                    style={{maxWidth:'100%'}}
                    src="/OnRoad.svg"
                    alt="On-Road"
                    loading="lazy"
                   
                />
            
            </motion.div>

          
            </Grid>

              

            </Grid>
          </Box>
          </Container>
          </section>
       
        </>
    )
}

export default Designer;