import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Papers from "./Paper";
import Guidemobile from "./Guidemobile";
import Contact from "./Contact";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';



const Whitepaper=()=>
{
    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>

            <Helmet>

            <link rel="canonical" href={canonicalUrl} />
            </Helmet>
         
             <div className="content">
              <Header/>
           <Papers/>
           <Guidemobile/>
           <Contact/>
            </div>
            <Footer/>

        </>
    )
}

export default Whitepaper;