import React from "react";
import Header from "./Header/Header";
import "./App.css";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Service from "./Home/Service";
import Product from "./Home/Product";
import Designer from "./Home/Designer";

import Glimpses from "./Home/Glimpses";
import Choose from "./Home/Choose";
import Brand from "./Home/Brand";
import Designerservices from "./Home/Designservices";


function App() {
  return (
  
    <>
    
            <div className="content">
               <Header/>
               <Home/>
               <Brand/>
               <Service/>
               <Product/>
               <Designer/>
               <Designerservices/>
               <Glimpses/>
               <Choose/>
            <Footer/>
           
            </div>
          
    </>
  )
}

export default App;

/*   
  <Product/>
   
                 
                 
                      
               

                */

