import React, { useState } from "react";
import { Box, Container, Grid} from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Accordian } from "./Accordian";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const  Wordpressservices=()=>
{
 
  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 const [openIndex, setOpenIndex] = useState(0);

 const handleAccordionClick = (index) => {
     setOpenIndex(index === openIndex ? -1 : index);
 };

    return (
        <>
          
           
           
          <section className="section" style={{backgroundColor:'#FFFDF3'}}>
            <Container maxWidth='lg'>
           
        
          
          <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="center" alignItems='center'>

            <Grid item xs={12} sm={isSmscreen?12:8} md={8} >
             
               <div className='superheroes'>

               <h1 className="page_title" style={{marginBottom:'0px'}}> Why Should you choose </h1>  

                <p className="line-height title text-center" style={{margin:'12px 0px 18px'}}>our wordpress Development Services ?</p>

               <p className="line-height text-center" style={{margin:'12px 0px 0px'}}> When developing a website, we involve our customer at every stage of the process. We follow an agile approach and encourage feedback allowing us to tweak the product until customer’s satisfaction. Our methodological approach has helped us work with some great clients and add some awesome website work to our portfolio </p>


               </div>
           
             
              </Grid>
              </Grid>
              <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display='flex' justifyContent='space-between' alignItems="center" style={{marginTop:isSmscreen?'40px':'80px'}}>

              <Grid  item xs={12} sm={isSmscreen ? 12 : 6} md={6}>
                   
              {Accordian.map((cur, index) => {

                     return (<>

                                <div className= {openIndex === index?"box-shadow border-radius mt-10":'border-bottom'} style={{ padding: isSmscreen ? '12px' : '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="title">{cur.title}</div>
                                        <div onClick={() => handleAccordionClick(index)} className="keyboardArrow">
                                            {openIndex === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </div>
                                    </div>
                                    {openIndex === index && <p className="footer_font" style={{fontWeight:'400'}}>{cur.label}</p>}

                                    </div>
                            

                     </>)
              })}
              </Grid>

                 <Grid  item xs={12} sm={isSmscreen ? 12 : 6} md={6} display="flex" alignItems='center'>

                    {Accordian.map((cur, index) => {

                    return (<>
                    
                    {openIndex === index && (
                        <LazyLoadImage
                            style={{ maxWidth: '100%' }}
                            src={cur.image}
                            alt={cur.image}
                            loading="lazy"
                        
                        />
                    )}
                       

                    </>)
                    })}

                    </Grid>

            
                </Grid>

           
       
          </Box>
          

          </Container>
          </section>

            


        </>
    )
}
export default Wordpressservices;

