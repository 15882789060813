import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Container from '@mui/material/Container';
import { createTheme, useMediaQuery } from "@mui/material";

const Join=()=>
{
    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))


     const [isImageLoaded, setImageLoaded] = useState(false);

     const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = './contact.png';
        img.onload = () => {
            setImageLoaded(true);
            setShowContent(true);
        };
    }, []);

  

    return(
        <>
            <section style={{backgroundColor:'#18143B',paddingBlock:'30px'}}>
            <Container maxWidth="lg">
           
            <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={2} columnSpacing={{ sm:2,md:4}} display="flex" justifyContent="space-between" alignItems="center" >
            <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
            {showContent && (
             <div>
             <div className="hero_title wireless_gadget " style={{color:'#FFE95A'}}>Contact us</div>
  	     	<div className="description"><p style={{color:'#ffffff'}}>Get in touch with us for all your business requirements and hire our optimal services for getting the project done with your budget estimates. We believe in building business partnerships that go long-term.</p></div>					
          </div> 
             )}
          </Grid>
           
       
          <Grid item xs ={12} sm={isSmscreen?12:6} md={6} display="flex" justifyContent={isSmscreen? 'center':'flex-end'} >
         
          
                <LazyLoadImage
                className="about_image"
                src='./contact.png'
                alt="contact"
                loading="lazy"
                style={{
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: isImageLoaded ? 1 : 0, 
                    transition: 'opacity 0.3s ease-in-out',
                }}
                />
           
      
        </Grid>
       
                </Grid>
                </Box>
            </Container>
     </section>
        </>
    )
}
export default Join;